import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'components/layout';
import styles from './Status.module.scss';

/**
 * Shows the status when data is unpopulated, missing, or unavailable due to technical error.
 * Includes an image as well as an optional heading and caption.
 * @function status
 * @param {Object} props
 * @return {Object}
 */
const Status = ({ asset, caption, heading, imageUrl }) => (
  <Card className={styles.container}>
    <h2>{heading}</h2>
    <img src={asset ? `https://assets.engageapps.ca/${asset}` : imageUrl} alt="current status" />
    <p>{caption}</p>
  </Card>
);

/**
 * Status prop types.
 * @type {Object}
 */
Status.propTypes = {
  asset: PropTypes.string,
  caption: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  heading: PropTypes.string,
  imageUrl: PropTypes.string
};

/**
 * Status default props.
 * @type {Object}
 */
Status.defaultProps = {
  asset: null,
  caption: null,
  heading: null,
  imageUrl: 'https://assets.engageapps.ca/undraw/for-sale.svg'
};

export default Status;
