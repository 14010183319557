import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'evergreen-ui';

const Group = ({ children, ...props }) => <Menu.Group {...props}>{children}</Menu.Group>;

Group.propTypes = {
  children: PropTypes.node.isRequired
};

export default Group;
