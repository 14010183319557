import React from 'react';
import PropTypes from 'prop-types';
import styles from 'components/form/formik/FormikSection.module.scss';
import { FormikSection as Section } from 'components/form/formik';
import { FeaturesGroup } from 'forms/groups';

/**
 * A form for updating testimonials.
 * @function FeaturesSection
 * @param {Object} props
 * @return {Object}
 */
const FeaturesSection = ({ values }) => (
  <Section>
    <div className={styles.heading}>
      <Section.Checkbox
        checked={values.content.featuresEnabled}
        id="featuresEnabled"
        label={<h3>Features</h3>}
        name="content.featuresEnabled"
      />
    </div>
    <FeaturesGroup category="Feature" name="content.features" values={values.content.features} />
  </Section>
);

/**
 * FeaturesSection prop types
 * @type {Object}
 */
FeaturesSection.propTypes = {
  values: PropTypes.shape({
    content: PropTypes.shape({
      features: PropTypes.arrayOf(
        PropTypes.shape({
          featureDescription: PropTypes.string.isRequired,
          featureHeading: PropTypes.string.isRequired,
          featureIcon: PropTypes.string
        })
      ),
      featuresEnabled: PropTypes.bool.isRequired
    })
  }).isRequired
};

export default FeaturesSection;
