import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withCurrentUser } from 'components/auth';

/**
 * Render the Intercom live chat interface.
 * @param {Object} currentUser
 * @return {Void}
 */
const Intercom = ({ appId, currentUser, location }) => {
  const settings = {
    app_id: appId
  };

  if (currentUser && !location.pathname.includes('/callback')) {
    settings.name = `${currentUser.firstName} ${currentUser.lastName}`;
    settings.user_id = currentUser.authId;
  }

  window.intercomSettings = settings;
  /* eslint-disable prettier/prettier, prefer-rest-params, func-names */
  return (
    <script>
      {(function(){const w=window;const ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{const d=document;const i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;const l=function(){const s=d.createElement('script');s.type='text/javascript';s.async=true;s.src=`https://widget.intercom.io/widget/${appId}`;const x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})()}
    </script>
  );
}

/**
 * Intercom prop types
 * @type {Object}
 */
Intercom.propTypes = {
  appId: PropTypes.string.isRequired,
  currentUser: PropTypes.shape({
    authId: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
};

/**
 * Intercom default props
 * @type {Object}
 */
Intercom.defaultProps = {
  currentUser: null
};

export default withCurrentUser(withRouter(Intercom));
