import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Button from './Button';
import styles from './RouterButton.module.scss';

/**
 * Wrap Button inside because we still want the intent, icon, prop type check, etc.
 * Also, don't use our own <Link> since we use React Router Dom's <NavLink> underneath; its styling breaks our RouterButton.
 */
const RouterButton = ({ to, ...props }) => (
  <span className={styles.container}>
    <Link to={to}>
      <Button onClick={() => undefined} {...props} />
    </Link>
  </span>
);

RouterButton.propTypes = {
  to: PropTypes.string.isRequired
};

export default React.memo(RouterButton);
