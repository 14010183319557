import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { FormikForm as Form } from 'components/form/formik';
import { TestimonialsSchema } from './schemas';
import { TestimonialsSection } from './sections';

/**
 * A container for forms under the 'Testimonials' tab.
 * @function TestimonialsForm
 * @param {Object} props
 * @return {Object}
 */
const TestimonialsForm = ({ content, onSubmit }) => (
  <Formik
    initialValues={{
      content: {
        testimonials: content.testimonials,
        testimonialsEnabled: content.testimonialsEnabled
      }
    }}
    onSubmit={onSubmit}
    validationSchema={TestimonialsSchema}
  >
    {({ dirty, isValid, values }) => (
      <Form dirty={dirty} isValid={isValid}>
        <TestimonialsSection values={values} />
      </Form>
    )}
  </Formik>
);

/**
 * TestimonialsForm prop types
 * @type {Object}
 */
TestimonialsForm.propTypes = {
  content: PropTypes.shape({
    testimonials: PropTypes.arrayOf(
      PropTypes.shape({
        testimonialBody: PropTypes.string.isRequired,
        testimonialCompany: PropTypes.string,
        testimonialImageId: PropTypes.number,
        testimonialName: PropTypes.string.isRequired
      })
    ),
    testimonialsEnabled: PropTypes.bool.isRequired
  }).isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default TestimonialsForm;
