import React from 'react';
import PropTypes from 'prop-types';
import styles from 'components/form/formik/FormikSection.module.scss';
import { FormikSection as Section } from 'components/form/formik';

/**
 * A form for updating calendar information.
 * @function CalendarSection
 * @param {Object} props
 * @return {Object}
 */
const CalendarSection = ({ values }) => (
  <Section>
    <div className={styles.heading}>
      <Section.Checkbox
        checked={values.content.calendarEnabled}
        id="calendarEnabled"
        label={<h3>Calendar</h3>}
        name="content.calendarEnabled"
      />
    </div>
    <Section.Pane>
      <Section.TextArea
        id="calendarScript"
        label="Calendar Script"
        name="content.calendarScript"
        placeholder='
          <!-- Calendly inline widget begin -->
          <div class="calendly-inline-widget" data-url="https://calendly.com/<your-account>" style="min-width:320px;height:580px;"></div>
          <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js"></script>
          <!-- Calendly inline widget end -->
        '
        rows="6"
      />
    </Section.Pane>
  </Section>
);

/**
 * CalendarSection prop types
 * @type {Object}
 */
CalendarSection.propTypes = {
  values: PropTypes.shape({
    content: PropTypes.shape({
      calendarEnabled: PropTypes.bool,
      calendarScript: PropTypes.string
    })
  }).isRequired
};

export default CalendarSection;
