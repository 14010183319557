import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import withAuth from './withAuth';

/**
 * Processes the callback following authentication.
 * @param {Object} props
 * @return {Object}
 */
const Callback = ({ auth, location }) => {
  useEffect(() => {
    if (/access_token|id_token|error/.test(location.hash)) {
      auth.handleLogin(location.hash);
    }
  });

  return null;
};

/**
 * Callback prop types
 * @type {Object}
 */
Callback.propTypes = {
  auth: PropTypes.shape({
    handleLogin: PropTypes.func
  }).isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string
  }).isRequired
};

export default withRouter(withAuth(Callback));
