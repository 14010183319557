import React from 'react';
import Auth from 'auth';

/**
 * Adds the current auth session to the
 * passed component.
 * @param {Component} Component
 */
const withAuth = (Component) => (props) => <Component auth={Auth} {...props} />;

export default withAuth;
