import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'components/avatar';
import { Menu } from 'components/menu';
import styles from './Profile.module.scss';

const Profile = ({ children, currentUser }) => (
  <span className={styles.container}>
    <Menu trigger={<Avatar user={currentUser} />}>{children}</Menu>
  </span>
);

Profile.propTypes = {
  /**
   * The Menu items, such as <Menu.Item>. These are the Profile menu options,
   * such as "Profile", "Settings", "Logout", etc.
   */
  children: PropTypes.node.isRequired,

  /**
   * The user object from Auth0, but we only need several fields.
   */
  currentUser: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    lastName: PropTypes.string.isRequired
  }).isRequired
};

export default Profile;
