import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Field } from 'formik';
import HelperText from 'components/form/HelperText';
import Label from 'components/form/Label';
import FormikCheckbox from './FormikCheckbox';
import styles from './FormikGroup.module.scss';

/**
 * Container component for a label, checkbox field and error message. For use in Formik forms.
 * @function FormikCheckboxGroup
 * @param {Object} props
 * @return {Object}
 */
const FormikCheckboxGroup = ({ checked, id, label, name }) => (
  <div className={styles.container}>
    <Label htmlFor={id}>
      <Field checked={checked} id={id} name={name} component={FormikCheckbox} />
      {label}
    </Label>
    <div className={styles.errorMessage}>
      <ErrorMessage name={name} intent="danger" size="medium" component={HelperText} />
    </div>
  </div>
);

/**
 * FormikCheckboxGroup prop types
 * @type {Object}
 */
FormikCheckboxGroup.propTypes = {
  checked: PropTypes.bool,
  id: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  name: PropTypes.string.isRequired
};

/**
 * FormikCheckboxGroup default props
 * @type {Object}
 */
FormikCheckboxGroup.defaultProps = {
  checked: false,
  label: null
};

export default FormikCheckboxGroup;
