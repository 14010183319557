import axios from 'axios';
import { combineReducers } from 'redux';
import { PASSPORT_API } from 'constants.js';
import { userTypes as types } from 'state/actions';

/**
 * Reducer for the current user's currentUser.
 * @param {Object} state - the user currentUser returned from and parsed by Auth0.
 * @param {Object} action - The currentUser object
 * @return {Object|null} - The currentUser object. Set it to null when user logs out.
 */
const currentUserReducer = (state = null, action) => {
  switch (action.type) {
    case types.CLEAR_CURRENT_USER:
      return null;
    case types.FETCH_CURRENT_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

/**
 * A generic error reducers for all failures.
 * @param {Object} state
 * @param {Object} action
 * @return {Object|null} The new error state. Set it to null when user logs out.
 */
const errorReducer = (state = null, action) => {
  switch (action.type) {
    case types.CLEAR_CURRENT_USER:
      return null;
    case types.CLEAR_CURRENT_USER_FAILED:
    case types.FETCH_CURRENT_FAILED:
      return action.error;
    default:
      return state;
  }
};

export default combineReducers({
  currentUser: currentUserReducer,
  error: errorReducer
});

/**
 * Called when Auth0 logs a user out.
 * Use this to trigger errorReducer and currentUserReducer to nullify their data.
 * @return {Object}
 */
export const clearCurrentUser = () => ({
  type: types.CLEAR_CURRENT_USER
});

/**
 * An easy way to trigger this is to keep logging in/out. Auth0 will return "Too many requests" error.
 * @param {Object} error - An error object.
 * @return {Object}
 */
export const failed = (error) => ({
  type: types.CLEAR_CURRENT_USER_FAILED,
  error
});

/**
 * Action creator called when fetching a user profile from Passport failed.
 * @param {Object} error
 * @return {Object}
 */
const fetchCurrentFailed = (error) => ({
  type: types.FETCH_CURRENT_FAILED,
  error
});

/**
 * Action creator called when fetching a user profile from Passport succeeeds.
 * @param {Object} auth
 * @param {Object} response
 * @return {Object}
 */
const fetchCurrentSuccess = (auth, response = null) => ({
  type: types.FETCH_CURRENT_SUCCESS,
  payload: { ...auth, ...response }
});

/**
 * Uses the auth0 auth token to fetch the current user's profile from the Passport API.
 * Called once Auth0 logs a user in. See Auth0.js -> setSession.
 * @param {Object} auth
 */
export const fetchCurrent = (auth) => (dispatch) => {
  dispatch({ type: types.FETCH_CURRENT });
  return axios({ baseURL: PASSPORT_API, url: encodeURI(`users/authId=${auth.authId}`) })
    .then(({ data }) => dispatch(fetchCurrentSuccess(auth, data)))
    .catch((error) => {
      dispatch(fetchCurrentSuccess(auth));
      return dispatch(fetchCurrentFailed(error));
    });
};
