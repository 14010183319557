import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'evergreen-ui';

const TextHeaderCell = ({ children, ...props }) => <Table.TextHeaderCell {...props}>{children}</Table.TextHeaderCell>;

TextHeaderCell.propTypes = {
  children: PropTypes.node.isRequired
};

export default TextHeaderCell;
