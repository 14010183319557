import React from 'react';
import PropTypes from 'prop-types';
import styles from 'components/form/formik/FormikSection.module.scss';
import { FormikSection as Section } from 'components/form/formik';

/**
 * A form for updating rate information.
 * @function RatesSection
 * @param {Object} props
 * @return {Object}
 */
const RatesSection = ({ values }) => (
  <Section>
    <div className={styles.heading}>
      <Section.Checkbox
        checked={values.content.ratesEnabled}
        id="ratesEnabled"
        label={<h3>Rates</h3>}
        name="content.ratesEnabled"
      />
    </div>
    <Section.Pane>
      <Section.Input id="ratesHeading" label="Rates Heading" name="content.ratesHeading" />
      <Section.TextArea id="ratesDescription" label="Rates Description" name="content.ratesDescription" rows="5" />
    </Section.Pane>
    <Section.Pane>
      <Section.Checkbox
        checked={values.content.ratesShowLogos}
        id="ratesShowLogos"
        label="Show Lender Logos"
        name="content.ratesShowLogos"
      />
    </Section.Pane>
  </Section>
);

/**
 * RatesSection prop types
 * @type {Object}
 */
RatesSection.propTypes = {
  values: PropTypes.shape({
    content: PropTypes.shape({
      ratesEnabled: PropTypes.bool,
      ratesShowLogos: PropTypes.bool
    })
  }).isRequired
};

export default RatesSection;
