import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { FormikForm as Form } from 'components/form/formik';
import { AppearanceSection, HeroSection } from './sections';
import { ThemeSchema } from './schemas';

/**
 * A container for forms under the 'Theme' tab.
 * @function ThemeForm
 * @param {Object} props
 * @return {Object}
 */
const ThemeForm = ({ content, onSubmit, theme }) => (
  <Formik
    initialValues={{
      content: {
        appearanceColorScheme: content.appearanceColorScheme,
        heroDescription: content.heroDescription,
        heroHeading: content.heroHeading,
        heroImageId: content.heroImageId,
        linksApplyOnline: content.linksApplyOnline,
        title: content.title
      },
      theme
    }}
    onSubmit={onSubmit}
    validationSchema={ThemeSchema}
  >
    {({ dirty, isValid }) => (
      <Form dirty={dirty} isValid={isValid}>
        <AppearanceSection />
        <HeroSection />
      </Form>
    )}
  </Formik>
);

/**
 * ThemeForm prop types
 * @type {Object}
 */
ThemeForm.propTypes = {
  content: PropTypes.shape({
    appearanceColorScheme: PropTypes.string,
    heroDescription: PropTypes.string,
    heroHeading: PropTypes.string,
    heroImageId: PropTypes.number,
    linksApplyOnline: PropTypes.string,
    title: PropTypes.string.isRequired
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired
};

export default ThemeForm;
