import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './Subcontent.module.scss';

const Subcontent = ({ children, withSubnavigation }) => (
  <div className={classNames(styles.container, { [styles.withSubnavigation]: withSubnavigation })}>{children}</div>
);

/**
 * Subcontent prop types
 * @type {Object}
 */
Subcontent.propTypes = {
  children: PropTypes.node.isRequired,
  withSubnavigation: PropTypes.bool
};

/**
 * Subcontent default props
 * @type {Object}
 */
Subcontent.defaultProps = {
  withSubnavigation: false
};

export default Subcontent;
