import React from 'react';
import PropTypes from 'prop-types';
import styles from 'components/form/formik/FormikSection.module.scss';
import { FormikSection as Section } from 'components/form/formik';
import { TestimonialsGroup } from 'forms/groups';

/**
 * A form for updating testimonials.
 * @function TestimonialsSection
 * @param {Object} props
 * @return {Object}
 */
const TestimonialsSection = ({ values }) => (
  <Section>
    <div className={styles.heading}>
      <Section.Checkbox
        checked={values.content.testimonialsEnabled}
        id="testimonialsEnabled"
        label={<h3>Testimonials</h3>}
        name="content.testimonialsEnabled"
      />
    </div>
    <TestimonialsGroup
      category="Testimonial"
      max="3"
      name="content.testimonials"
      values={values.content.testimonials}
    />
  </Section>
);

/**
 * TestimonialsSection prop types
 * @type {Object}
 */
TestimonialsSection.propTypes = {
  values: PropTypes.shape({
    content: PropTypes.shape({
      testimonials: PropTypes.arrayOf(
        PropTypes.shape({
          testimonialBody: PropTypes.string.isRequired,
          testimonialCompany: PropTypes.string,
          testimonialImageId: PropTypes.number,
          testimonialName: PropTypes.string.isRequired
        })
      ),
      testimonialsEnabled: PropTypes.bool.isRequired
    })
  }).isRequired
};

export default TestimonialsSection;
