import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'components/button';
import { Plain } from 'components/layout';
import { APP_NAME, LOGO_URL } from 'constants.js';
import { withAuth } from 'components/auth';

/**
 * A basic public homepage for non-authenticated visitors.
 * @function Homepage
 * @param {Object} props
 * @return {Object}
 */
const Homepage = ({ auth }) => (
  <Plain>
    <h1>Welcome to</h1>
    <img src={LOGO_URL} alt={APP_NAME} style={{ margin: 25, maxHeight: 100 }} />
    <h2>Please log in to get started</h2>
    <Button icon="user" intent="primary" onClick={auth.login}>
      Login
    </Button>
  </Plain>
);

/**
 * Homepage prop types
 * @type {Object}
 */
Homepage.propTypes = {
  auth: PropTypes.shape({
    login: PropTypes.func
  }).isRequired
};

export default withAuth(Homepage);
