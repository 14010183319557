import React from 'react';
import PropTypes from 'prop-types';
import styles from './Row.module.scss';

const Row = ({ children, className }) => <div className={`${styles.container} ${className}`}>{children}</div>;

Row.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

Row.defaultProps = {
  className: null
};

export default Row;
