import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'evergreen-ui';

const Body = ({ children, ...props }) => <Table.Body {...props}>{children}</Table.Body>;

Body.propTypes = {
  children: PropTypes.node.isRequired
};

export default Body;
