import { mediaTypes, siteTypes, snackbarTypes as types } from 'state/actions';

/**
 * Snackbar reducer
 * @param {Object} [state={}]
 * @return {Object}
 */
export default (state = {}, action) => {
  switch (action.type) {
    case types.CLEAR:
      return {};
    case mediaTypes.DELETE_FAILED:
    case mediaTypes.UPLOAD_FAILED:
    case siteTypes.CREATE_FAILED:
    case siteTypes.DESTROY_FAILED:
    case siteTypes.UPDATE_FAILED:
      return {
        intent: 'danger',
        messages: [action.error.response.data.userMessage]
      };
    case siteTypes.CREATE_FAILED_422:
    case siteTypes.UPDATE_FAILED_422:
      return {
        intent: 'danger',
        messages: action.error
      };
    case mediaTypes.DELETE_SUCCESS:
    case mediaTypes.UPLOAD_SUCCESS:
    case siteTypes.CREATE_SUCCESS:
    case siteTypes.DESTROY_SUCCESS:
    case siteTypes.UPDATE_SUCCESS:
      return {
        intent: 'success',
        messages: [action.payload.userMessage]
      };
    default:
      return state;
  }
};

/**
 * Action creator for clearing the snackbar.
 * @return {Void}
 */
export const clear = () => (dispatch) => {
  dispatch({ type: types.CLEAR });
};
