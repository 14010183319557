// Evergreeen's Toaster documentation: https://evergreen.segment.com/components/toaster/
import PropTypes from 'prop-types';
import { toaster } from 'evergreen-ui';

/**
 * Map our typical intents, such as "primary", "default", "danger", etc, to Evergreen's `toaster` APIs,
 * such as `.success()`, `.warning()`, `.danger()`, `.notify()` .
 * Notice they don't use `primary` as one of their intents; in this case, `.notify()` is the blue one.
 *
 * @param {String} intent - "sucess", "warning", "danger", "default", "primary", etc.
 * @return {Function} A reference to Evergreen's `toaster` methods.
 */
const getToasterAction = (intent) => {
  let action;

  switch (intent) {
    case 'success':
      action = toaster.success;
      break;
    case 'warning':
      action = toaster.warning;
      break;
    case 'danger':
      action = toaster.danger;
      break;
    default:
      action = toaster.notify;
  }

  return action;
};

/**
 * Unlike a normal component, toaster wraps Evergreen's `<Alert>` and makes use of **side effects** to show a toaster,
 * hence the `return null` inside the component. So, our `<Snackbar>` is not a real React component; I wrote it this way only
 * to make developer usage consistant. That also means we can't customize `<Alert>`'s appearance such as its positioning, since
 * Evergreen doesn't expose an API to override the style.
 *
 * Evergreen also mentions of a `duration` parameter that I can put into the `showToaster` function;
 * but my own testing indicates it doesn't seem to work.
 *
 * See the source code on GitHub at:
 * https://github.com/segmentio/evergreen/blob/master/src/toaster/src/ToastManager.js, Line 7
 */
const Snackbar = ({ children, intent }) => {
  const showToaster = getToasterAction(intent);

  showToaster(children);
  return null;
};

Snackbar.propTypes = {
  children: PropTypes.string.isRequired,
  intent: PropTypes.string
};

Snackbar.defaultProps = {
  intent: 'default'
};

export default Snackbar;
