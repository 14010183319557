import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Field } from 'formik';
import HelperText from 'components/form/HelperText';
import { Button, IconButton } from 'components/button';
import FormikInput from './FormikInput';
import errorStyles from './FormikGroup.module.scss';
import styles from './FormikInputRepeater.module.scss';

/**
 * Form element for handling arrays of text input fields. For use in Formik forms.
 * @function FormikInputRepeater
 * @param {Object} props
 * @return {Object}
 */
const FormikInputRepeater = ({ arrayHelpers, category, max, name, values }) => (
  <div className={styles.container}>
    {values &&
      values.length > 0 &&
      values.map((value, index) => (
        /* eslint-disable react/no-array-index-key */
        <div key={index}>
          <Field name={`${name}.${index}`} component={FormikInput} />
          <div className={errorStyles.errorMessage}>
            <ErrorMessage name={`${name}.${index}`} intent="danger" size="medium" component={HelperText} />
          </div>
          <IconButton type="button" name="trash" onClick={() => arrayHelpers.remove(index)} />
        </div>
      ))}
    <div>
      <Button
        disabled={values && values.length >= max}
        icon="add"
        intent="primary"
        onClick={() => arrayHelpers.push('')}
        type="button"
      >
        {`Add ${category}`}
      </Button>
    </div>
  </div>
);

/**
 * FormikInputRepeater prop types
 * @type {Object}
 */
FormikInputRepeater.propTypes = {
  arrayHelpers: PropTypes.shape({
    push: PropTypes.func,
    remove: PropTypes.func
  }).isRequired,
  category: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string)
};

/**
 * FormikInputRepeater default props
 * @type {Object}
 */
FormikInputRepeater.defaultProps = {
  values: []
};

export default FormikInputRepeater;
