import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './HelperText.module.scss';

const HelperText = ({ children, intent, size }) => (
  <div className={classNames(styles.container, styles[intent], styles[size])}>{children}</div>
);

HelperText.propTypes = {
  children: PropTypes.string.isRequired,
  /* The intent of the text. 'default' for gray-ish, 'success' for green, 'danger' for red. */
  intent: PropTypes.string,
  /* The size of the text: small, medium, large, jumbo */
  size: PropTypes.string
};

HelperText.defaultProps = {
  intent: 'default',
  size: 'small'
};

export default HelperText;
