/**
 * Retrieves the snackbar intent and message following an action.
 * @param {Object} state
 * @return {Object}
 */
export const get = (state) => state.snackbar;

export default {
  get
};
