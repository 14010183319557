import { defaultValues } from './constants';

/**
 * Replaces null values with default values.
 * @param {String} key
 * @param {*} value
 * @return {*}
 */
export const setDefault = (key, value) => (value === null && key in defaultValues ? defaultValues[key] : value);

/**
 * Nullifies values set to empty strings or arrays.
 * @param {*} value
 * @return {?*}
 */
export const setNull = (_, value) => (value === '' || (Array.isArray(value) && !value.length) ? null : value);

/**
 * Recursively traverses a deeply nested object and uses a callback to transform data.
 * @param {Object} data
 * @param {Function} callback
 * @return {Object}
 */
export const deepTransform = (data, callback) => {
  const clone = typeof data === 'object' ? { ...data } : data;
  Object.keys(clone).forEach((key) => {
    if (Array.isArray(clone[key])) {
      clone[key] = clone[key].map((element) =>
        typeof element === 'object' ? deepTransform(element, callback) : callback(key, element)
      );
    } else if (clone[key] && typeof clone[key] === 'object') {
      clone[key] = deepTransform(clone[key], callback);
    }
    clone[key] = callback(key, clone[key]);
  });
  return clone;
};
