import { API_BASE_URL, SENTRY_DSN, SENTRY_ENVIRONMENT } from 'constants.js';
import axios from 'axios';
import * as Sentry from '@sentry/browser';

axios.defaults.baseURL = API_BASE_URL;
window.axios = axios;

if (SENTRY_DSN) {
  Sentry.init({ dsn: SENTRY_DSN, environment: SENTRY_ENVIRONMENT });
}
