import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'components/navigation';
import { Plain } from 'components/layout';
import { Status } from 'components/status';

/**
 * View for specifying that a given resource could not be found.
 * @function NotFound
 * @param {Object} props
 * @return {Object}
 */
const NotFound = ({ link, page, resource }) => (
  <Plain>
    <Status
      caption={
        <span>
          Return to <Link to={link}>{page}</Link>.
        </span>
      }
      heading={`${resource} Doesn't Exist`}
      asset="undraw/not-found.svg"
    />
  </Plain>
);

/**
 * NotFound prop types
 * @type {Object}
 */
NotFound.propTypes = {
  link: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired
};

export default NotFound;
