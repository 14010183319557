/**
 * Array of possible color scheme options.
 * @type {Array}
 */
export const colorOptions = [
  {
    display: 'Blue',
    value: 'blue'
  },
  {
    display: 'Green',
    value: 'green'
  },
  {
    display: 'Red',
    value: 'red'
  }
];

/**
 * Array of possible theme options.
 * @type {Array}
 */
export const themeOptions = [
  {
    display: 'Classic',
    value: 'engage-classic'
  },
  {
    display: 'Modern',
    value: 'engage-modern'
  },
  {
    display: 'Traditional',
    value: 'engage-traditional'
  }
];
