import * as Yup from 'yup';

/**
 * Yup validation schema for Testimonials form
 * @type {Object}
 */
const FeaturesSchema = Yup.object().shape({
  content: Yup.object().shape({
    features: Yup.array().of(
      Yup.object().shape({
        featureDescription: Yup.string().required('Feature description is required.'),
        featureHeading: Yup.string().required('Feature heading is required.'),
        featureIcon: Yup.string().nullable()
      })
    ),
    featuresEnabled: Yup.boolean().required('Feature enabled is required.')
  })
});

export default FeaturesSchema;
