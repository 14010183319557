import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import withAuth from './withAuth';

const PrivateRoute = ({ auth, scopes, path, location, ...props }) => {
  if (auth.isAuthenticated()) {
    return <Route path={path} {...props} />;
  }
  localStorage.setItem('next', location.pathname);
  auth.renewSession();
  return null;
};

PrivateRoute.propTypes = {
  auth: PropTypes.shape({
    isAuthenticated: PropTypes.func,
    userHasScopes: PropTypes.func
  }).isRequired,
  path: PropTypes.string.isRequired,
  scopes: PropTypes.arrayOf(PropTypes.string),
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
};

PrivateRoute.defaultProps = {
  scopes: []
};

export default withAuth(PrivateRoute);
