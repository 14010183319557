/**
 * Default values for new resource
 * @type {Object}
 */
export const resourceDefaults = {
  resourceDescription: '',
  resourceName: ''
};

/**
 * Default values for new feature
 * @type {Object}
 */
export const featureDefaults = {
  featureDescription: '',
  featureHeading: '',
  featureIcon: null
};

/**
 * Default values for new testimonial
 * @type {Object}
 */
export const testimonialDefaults = {
  testimonialBody: '',
  testimonialCompany: '',
  testimonialImageId: null,
  testimonialName: ''
};
