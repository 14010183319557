import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'components/layout';
import FormikArrayGroup from './FormikArrayGroup';
import FormikCheckboxGroup from './FormikCheckboxGroup';
import FormikDropdownGroup from './FormikDropdownGroup';
import FormikInputGroup from './FormikInputGroup';
import FormikPane from './FormikPane';
import FormikSelectGroup from './FormikSelectGroup';
import FormikTextAreaGroup from './FormikTextAreaGroup';
import styles from './FormikSection.module.scss';

/**
 * Container component for a form section. For use in Formik forms.
 * @function FormikSection
 * @param {Object} props
 * @return {Object}
 */
const FormikSection = ({ children }) => <Card className={styles.container}>{children}</Card>;

/**
 * FormikSection prop types
 * @type {Object}
 */
FormikSection.propTypes = {
  children: PropTypes.node.isRequired
};

FormikSection.Array = FormikArrayGroup;
FormikSection.Checkbox = FormikCheckboxGroup;
FormikSection.Dropdown = FormikDropdownGroup;
FormikSection.Input = FormikInputGroup;
FormikSection.Pane = FormikPane;
FormikSection.Select = FormikSelectGroup;
FormikSection.TextArea = FormikTextAreaGroup;

export default FormikSection;
