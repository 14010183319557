import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon as BlueprintIcon } from '@blueprintjs/core';
import styles from './Icon.module.scss';

const Icon = ({ className, iconSize, intent, name }) => (
  <BlueprintIcon
    className={classNames([styles.container], className, styles[intent])}
    icon={name}
    iconSize={iconSize}
  />
);

Icon.propTypes = {
  className: PropTypes.string,
  iconSize: PropTypes.number,
  intent: PropTypes.oneOf(['danger', 'default', 'disabled', 'primary', 'success', 'withinButton']),
  name: PropTypes.string.isRequired
};

Icon.defaultProps = {
  className: null,
  iconSize: 12,
  intent: 'default'
};

export default React.memo(Icon);
