import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Field } from 'formik';
import HelperText from 'components/form/HelperText';
import Label from 'components/form/Label';
import FormikInput from './FormikInput';
import styles from './FormikGroup.module.scss';

/**
 * Container component for a label, input field and error message. For use in Formik forms.
 * @function FormikInputGroup
 * @param {Object} props
 * @return {Object}
 */
const FormikInputGroup = ({ id, followText, label, name, placeholder }) => (
  <div className={styles.container}>
    <Label htmlFor={id}>{label}</Label>
    <Field id={id} followText={followText} name={name} placeholder={placeholder} component={FormikInput} />
    <div className={styles.errorMessage}>
      <ErrorMessage name={name} intent="danger" size="medium" component={HelperText} />
    </div>
  </div>
);

/**
 * FormikInputGroup prop types
 * @type {Object}
 */
FormikInputGroup.propTypes = {
  id: PropTypes.string.isRequired,
  followText: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string
};

/**
 * FormikInputGroup default props
 * @type {Object}
 */
FormikInputGroup.defaultProps = {
  followText: null,
  placeholder: null
};

export default FormikInputGroup;
