import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Column.module.scss';

const Column = ({ children, className, flex }) => (
  <div className={classNames(styles.container, className)} style={{ flex }}>
    {children}
  </div>
);

Column.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  flex: PropTypes.number
};

Column.defaultProps = {
  className: '',
  flex: 1
};

export default Column;
