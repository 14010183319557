import { breadcrumbTypes as types, siteTypes } from 'state/actions';

/**
 * Breadcrumb reducer
 * @param {Object} [state=[]]
 * @return {Object}
 */
export default (state = [], action) => {
  const clone = [...state];
  switch (action.type) {
    case types.UPDATE:
      return action.breadcrumbs;
    case siteTypes.UPDATE_SUCCESS:
      clone[clone.length - 1].name = action.payload.content.title;
      return clone;
    default:
      return state;
  }
};

/**
 * Action creator for updating breadcrumbs.
 * @param {Object} breadcrumbs
 * @return {Void}
 */
export const update = (breadcrumbs) => (dispatch) => {
  dispatch({ type: types.UPDATE, breadcrumbs });
};
