import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Card.module.scss';

const Card = ({ children, className }) => (
  <div className={classNames(styles.container, className, { [styles.defaultSpacing]: !className })}>{children}</div>
);

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

Card.defaultProps = {
  className: ''
};

export default Card;
