import React from 'react';
import PropTypes from 'prop-types';
import styles from 'components/form/Input.module.scss';

/**
 * An input field for use with the Formik library.
 * @function FormikInput
 * @param {Object} props
 * @return {Object}
 */
const FormikInput = ({ field, followText, form, ...props }) => (
  <div className={styles.container}>
    <input {...field} {...props} className={styles.input} type="text" />
    {followText && <span>{followText}</span>}
  </div>
);

/**
 * FormikInput prop types
 * @type {Object}
 */
FormikInput.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  field: PropTypes.object.isRequired,
  followText: PropTypes.string,
  form: PropTypes.object.isRequired
};

/**
 * FormikInput default props
 * @type {Object}
 */
FormikInput.defaultProps = {
  followText: null
};

export default FormikInput;
