const CLEAR_CURRENT_USER = 'users/CLEAR_CURRENT_USER';
const CLEAR_CURRENT_USER_FAILED = 'users/FAILED';
const FETCH_CURRENT = 'users/FETCH_CURRENT';
const FETCH_CURRENT_SUCCESS = 'users/FETCH_CURRENT_SUCCESS';
const FETCH_CURRENT_FAILED = 'users/FETCH_CURRENT_FAILED';

/**
 * User action types.
 * @type {Object}
 */
export default {
  CLEAR_CURRENT_USER,
  CLEAR_CURRENT_USER_FAILED,
  FETCH_CURRENT,
  FETCH_CURRENT_FAILED,
  FETCH_CURRENT_SUCCESS
};
