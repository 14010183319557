import React from 'react';
import PropTypes from 'prop-types';
import Content from './Content';
import Main from './Main';

/**
 * A bare container component for rendering a plain content page
 * @function Plain
 */
const Plain = ({ children }) => (
  <Main>
    <Content>{children}</Content>
  </Main>
);

/**
 * Plain prop types
 * @type {Object}
 */
Plain.propTypes = {
  children: PropTypes.node.isRequired
};

export default Plain;
