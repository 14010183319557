// Followed the guide "How to style checkbox without using any CSS framework." at
// https://appitventures.com/blog/styling-checkbox-css-tips/
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from 'components/form/Checkbox.module.scss';

/**
 * A custom checkbox component for use with the Formik Library
 * @class FormikCheckbox
 * @extends Component
 */
class FormikCheckbox extends Component {
  /**
   * FormikCheckbox prop types
   * @type {Object}
   */
  static propTypes = {
    checked: PropTypes.bool, // Match the naming to the native property
    field: PropTypes.shape({
      name: PropTypes.string
    }).isRequired,
    form: PropTypes.shape({
      setFieldValue: PropTypes.func
    }).isRequired,
    id: PropTypes.string
  };

  /**
   * FormikCheckbox default props
   * @type {Object}
   */
  static defaultProps = {
    checked: false,
    id: null
  };

  /**
   * Constructor
   * @param {Object} props
   * @return {Void}
   */
  constructor(props) {
    super(props);
    this.state = {
      checked: props.checked
    };
    this.onChange = this.onChange.bind(this);
  }

  /**
   * Handles onChange event.
   * @param {Object} event
   * @return {Void}
   */
  onChange(event) {
    const { checked } = event.target;
    this.setState({ checked }, () => this.props.form.setFieldValue(this.props.field.name, checked));
  }

  /**
   * Renders the FormkCheckbox component
   * @return {Object}
   */
  render() {
    return (
      <div className={styles.container}>
        {/* The HTML input which listens to click inputs, but is hidden since it can't be styled */}
        <input
          checked={this.state.checked}
          className={styles.input}
          id={this.props.id}
          onChange={this.onChange}
          type="checkbox"
        />
        {/* A custom styled square with a tick inside. */}
        <span className={styles.checkbox} />
      </div>
    );
  }
}

export default FormikCheckbox;
