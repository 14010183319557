import React from 'react';
import { FormikSection as Section } from 'components/form/formik';

/**
 * A form for updating social media links.
 * @function SocialMediaSection
 * @return {Object}
 */
const SocialMediaSection = () => (
  <Section>
    <h3>Social Media</h3>
    <Section.Pane>
      <Section.Input
        id="agentFacebook"
        label="Facebook URL"
        name="content.agentFacebook"
        placeholder="e.g., https://facebook.com/johndoe"
      />
      <Section.Input
        id="agentTwitter"
        label="Twitter URL"
        name="content.agentTwitter"
        placeholder="e.g., https://twitter.com/johndoe"
      />
    </Section.Pane>
    <Section.Pane>
      <Section.Input
        id="agentLinkedIn"
        label="LinkedIn URL"
        name="content.agentLinkedIn"
        placeholder="e.g., https://linkedin.com/in/johndoe"
      />
      <Section.Input
        id="agentInstagram"
        label="Instagram URL"
        name="content.agentInstagram"
        placeholder="e.g., https://instagram.com/johndoe"
      />
    </Section.Pane>
  </Section>
);

export default SocialMediaSection;
