import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components/icon';
import styles from './Search.module.scss';

class Search extends PureComponent {
  static propTypes = {
    onEscape: PropTypes.func,
    placeholder: PropTypes.string,
    search: PropTypes.func.isRequired
  };

  static defaultProps = {
    onEscape: () => undefined,
    placeholder: 'Search...'
  };

  constructor(props) {
    super(props);

    this.state = {
      searchTerm: ''
    };

    this.onChange = this.onChange.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.clear = this.clear.bind(this);

    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.inputRef.current.focus();
  }

  onChange(event) {
    const newTerm = event.target.value;

    this.setState({
      searchTerm: newTerm
    });

    this.props.search(newTerm);
  }

  onKeyUp(event) {
    if (event.key === 'Escape') {
      this.props.onEscape();
    }
  }

  clear() {
    this.setState({
      searchTerm: ''
    });

    this.props.search('');
  }

  render() {
    return (
      <div className={styles.container}>
        <Icon name="search" />

        <input
          className={styles.input}
          onChange={this.onChange}
          onKeyUp={this.onKeyUp}
          placeholder={this.props.placeholder}
          ref={this.inputRef}
          type="text"
          value={this.state.searchTerm}
        />

        {this.state.searchTerm && (
          <button type="button" onClick={this.clear} className={styles.button}>
            <Icon name="backspace " />
          </button>
        )}
      </div>
    );
  }
}

export default Search;
