import React from 'react';
import PropTypes from 'prop-types';
import styles from './FormikSection.module.scss';

/**
 * A container component for aligning formik fields to the left or right side of the form.
 * @function FormikPane
 * @param {Object} props
 * @return {Object}
 */
const FormikPane = ({ children }) => <div className={styles.pane}>{children}</div>;

/**
 * FormikPane prop types
 * @type {Object}
 */
FormikPane.propTypes = {
  children: PropTypes.node.isRequired
};

export default FormikPane;
