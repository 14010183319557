import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { AboutForm, FeaturesForm, Media, ResourcesForm, SettingsForm, TestimonialsForm, ThemeForm } from 'forms';
import { actions, selectors } from 'state';
import { Link, NavLink, SubNavigation } from 'components/navigation';
import { NotFound } from 'components/errors';
import { Subcontent } from 'components/layout';
import { Status } from 'components/status';
import { transformData } from 'transformations';
import { sitesCrumbs } from './breadcrumbs';

/**
 * Update site page located at the /sites/:id/update route.  A broker can update an existing site from this page.
 * @class UpdateSite
 * @extends {Component}
 */
class UpdateSite extends Component {
  /**
   * UpdateSite prop types
   * @type {Object}
   */
  static propTypes = {
    actions: PropTypes.shape({
      breadcrumbs: PropTypes.shape({
        update: PropTypes.func
      }),
      media: PropTypes.shape({
        clear: PropTypes.func,
        fetchBySiteId: PropTypes.func
      }),
      sites: PropTypes.shape({
        fetchById: PropTypes.func,
        update: PropTypes.func
      }),
      snackbar: PropTypes.shape({
        clear: PropTypes.func
      })
    }).isRequired,
    match: PropTypes.shape({
      params: PropTypes.shape({
        id: PropTypes.string
      })
    }).isRequired,
    hasMedia: PropTypes.bool.isRequired,
    site: PropTypes.shape({
      id: PropTypes.number,
      content: PropTypes.shape({
        title: PropTypes.string
      }),
      theme: PropTypes.string,
      url: PropTypes.string
    }),
    siteError: PropTypes.shape({
      status: PropTypes.number
    })
  };

  /**
   * UpdateSite default props
   * @type {Object}
   */
  static defaultProps = {
    site: {
      content: '',
      url: ''
    },
    siteError: null
  };

  /**
   * Constructor
   * @return {Void}
   */
  constructor(props) {
    super(props);
    this.state = {
      loaded: false,
      redirect: false
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * ComponentDidMount
   * @return {Void}
   */
  componentDidMount() {
    const { breadcrumbs, media, sites } = this.props.actions;
    sites.fetchById(this.props.match.params.id).then(({ error }) => {
      if (error) {
        breadcrumbs.update(sitesCrumbs);
      } else {
        const crumbs = [
          ...sitesCrumbs,
          {
            name: this.props.site.content.title,
            url: this.props.site.url
          }
        ];
        breadcrumbs.update(crumbs);
        media.fetchBySiteId(this.props.match.params.id).then(() => this.setState({ loaded: true }));
      }
    });
  }

  /**
   * ComponentWillUnmount
   * @return {Void}
   */
  componentWillUnmount() {
    this.props.actions.media.clear();
  }

  /**
   * Handles new site form submission
   * @param {Object} values
   * @return {Void}
   */
  onSubmit(data, { resetForm }) {
    const transformedData = transformData(data);
    const payload = JSON.parse(JSON.stringify(transformedData));
    resetForm({ values: transformedData });
    this.props.actions.sites.update(this.props.site.id, payload).then(() => this.props.actions.snackbar.clear());
  }

  /**
   * Renders the UpdateSite component
   * @return {Object}
   */
  render() {
    const { hasMedia, site, siteError } = this.props;
    const { loaded } = this.state;
    return (
      (loaded && (
        <Fragment>
          <SubNavigation fixed>
            <NavLink exact to={`/sites/${site.id}/update/about`}>
              About
            </NavLink>
            <NavLink exact to={`/sites/${site.id}/update/theme`}>
              Theme
            </NavLink>
            <NavLink exact to={`/sites/${site.id}/update/testimonials`}>
              Testimonials
            </NavLink>
            <NavLink exact to={`/sites/${site.id}/update/features`}>
              Features
            </NavLink>
            <NavLink exact to={`/sites/${site.id}/update/settings`}>
              Settings
            </NavLink>
            <NavLink exact to={`/sites/${site.id}/update/resources`}>
              Resources
            </NavLink>
            <NavLink exact to={`/sites/${site.id}/update/media`}>
              Media
            </NavLink>
          </SubNavigation>
          <Subcontent withSubnavigation>
            <Switch>
              <Route
                exact
                path="/sites/:id/update/about"
                render={() => <AboutForm content={site.content} onSubmit={this.onSubmit} />}
              />
              <Route
                exact
                path="/sites/:id/update/theme"
                render={() => <ThemeForm content={site.content} onSubmit={this.onSubmit} theme={site.theme} />}
              />
              <Route
                exact
                path="/sites/:id/update/testimonials"
                render={() => <TestimonialsForm content={site.content} onSubmit={this.onSubmit} />}
              />
              <Route
                exact
                path="/sites/:id/update/features"
                render={() => <FeaturesForm content={site.content} onSubmit={this.onSubmit} />}
              />
              <Route
                exact
                path="/sites/:id/update/settings"
                render={() => <SettingsForm content={site.content} onSubmit={this.onSubmit} />}
              />
              <Route
                exact
                path="/sites/:id/update/resources"
                render={() =>
                  hasMedia ? (
                    <ResourcesForm content={site.content} onSubmit={this.onSubmit} />
                  ) : (
                    <Status
                      asset="undraw/empty.svg"
                      caption={<Link to={`/sites/${site.id}/update/media`}>Upload media files to get started.</Link>}
                      heading="Resources Require Media"
                    />
                  )
                }
              />
              <Route
                exact
                path="/sites/:id/update/media"
                render={() => <Media content={site.content} onSubmit={this.onSubmit} siteId={site.id} />}
              />
            </Switch>
          </Subcontent>
        </Fragment>
      )) ||
      (siteError && siteError.status === 404 && <NotFound resource="Site" link="/sites" page="Sites" />)
    );
  }
}

/**
 * Map state to props
 * @param {Object} state
 * @param {Object} props
 * @return {Object}
 */
const mapState = (state, props) => ({
  hasMedia: selectors.media.hasAny(state),
  site: selectors.sites.getSite(state, props.match.params.id),
  siteError: selectors.sites.getErrors(state)
});

/**
 * Map dispatch to props
 * @param {Function} dispatch
 * @return {Object}
 */
const mapDispatch = (dispatch) => ({
  actions: {
    breadcrumbs: bindActionCreators(actions.breadcrumbs, dispatch),
    media: bindActionCreators(actions.media, dispatch),
    sites: bindActionCreators(actions.sites, dispatch),
    snackbar: bindActionCreators(actions.snackbar, dispatch)
  }
});

export default connect(mapState, mapDispatch)(UpdateSite);
