import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions, selectors } from 'state';
import { Actions } from 'components/layout';
import { Button } from 'components/button';
import { FiletypeIcon } from 'components/icon';
import { Loader } from 'components/loader';
import { Status } from 'components/status';
import { SubmitModal } from 'components/submit';
import { Table } from 'components/table';
import { formatDate } from 'transformations';

/**
 * A component for uploading files under the 'Media' tab.
 * @class Media
 * @extends {Component}
 */
class Media extends Component {
  /**
   * Media prop types
   * @type {Object}
   */
  static propTypes = {
    actions: PropTypes.shape({
      media: PropTypes.shape({
        deleteById: PropTypes.func.isRequired,
        fetchBySiteId: PropTypes.func,
        upload: PropTypes.func.isRequired
      }),
      snackbar: PropTypes.shape({
        clear: PropTypes.func
      })
    }).isRequired,
    hasMedia: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    media: PropTypes.arrayOf(
      PropTypes.shape({
        createdAt: PropTypes.string.isRequired,
        filename: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired
      })
    ).isRequired,
    siteId: PropTypes.number.isRequired,
    progress: PropTypes.number.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
    this.closeModal = this.closeModal.bind(this);
    this.deleteMedia = this.deleteMedia.bind(this);
    this.openModal = this.openModal.bind(this);
    this.upload = this.upload.bind(this);
  }

  /**
   * ComponentDidMount
   * @return {Void}
   */
  componentDidMount() {
    this.props.actions.media.fetchBySiteId(this.props.siteId);
  }

  /**
   * Closes the submission modal.
   * @return {Void}
   */
  closeModal() {
    this.setState({ showModal: false });
  }

  /**
   * Deletes an asset by ID.
   * @param {Number} siteId
   */
  deleteMedia(mediaId) {
    this.props.actions.media.deleteById(this.props.siteId, mediaId).then(() => this.props.actions.snackbar.clear());
  }

  /**
   * Opens the submission modal.
   * @return {Void}
   */
  openModal() {
    this.setState({ showModal: true });
  }

  /**
   * Dispatches the upload action.
   * @param {Number} siteId
   * @param {Object} payload
   */
  upload(siteId, payload) {
    this.props.actions.media.upload(siteId, payload).then(() => this.props.actions.snackbar.clear());
    this.closeModal();
  }

  /**
   * Renders the Media component.
   * @return {Object}
   */
  render() {
    const { hasMedia, isLoading, media, progress, siteId } = this.props;
    const { showModal } = this.state;
    return (
      <Fragment>
        <Loader.ProgressRing show={isLoading} progress={progress} />
        <Actions>
          <Button icon="add" intent="success" onClick={this.openModal}>
            Upload File
          </Button>
        </Actions>
        {hasMedia ? (
          <Table>
            <Table.Head>
              <Table.TextHeaderCell flex={1}>Thumbnail</Table.TextHeaderCell>
              <Table.TextHeaderCell flex={2}>Filename</Table.TextHeaderCell>
              <Table.TextHeaderCell flex={1}>Created</Table.TextHeaderCell>
              <Table.TextHeaderCell flex={1}>Last Updated</Table.TextHeaderCell>
              <Table.TextHeaderCell flex={1}>In Use</Table.TextHeaderCell>
              <Table.TextHeaderCell flex={2}>Actions</Table.TextHeaderCell>
            </Table.Head>
            <Table.Body>
              {media.map(({ createdAt, filename, id, inUse, mimeType, updatedAt, url }) => (
                <Table.Row key={id}>
                  <Table.TextCell flex={1}>
                    {mimeType.startsWith('image') ? (
                      <img src={url} alt={`${filename} thumbnail`} />
                    ) : (
                      <FiletypeIcon mediaType={mimeType} />
                    )}
                  </Table.TextCell>
                  <Table.TextCell flex={2}>{filename}</Table.TextCell>
                  <Table.TextCell flex={1}>{formatDate(createdAt)}</Table.TextCell>
                  <Table.TextCell flex={1}>{formatDate(updatedAt)}</Table.TextCell>
                  <Table.TextCell flex={1}>{inUse && <>&#10004;</>}</Table.TextCell>
                  <Table.TextCell flex={2}>
                    <Button disabled={inUse} icon="disable" intent="danger" onClick={() => this.deleteMedia(id)}>
                      Delete File
                    </Button>
                  </Table.TextCell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        ) : (
          <Status asset="undraw/empty.svg" heading="You don't have any media." />
        )}
        <SubmitModal
          allowMultipleFiles
          contextId={siteId}
          onClose={this.closeModal}
          show={showModal}
          upload={this.upload}
        />
      </Fragment>
    );
  }
}

/**
 * Map state to props
 * @param {Object} state
 * @return {Object}
 */
const mapState = (state) => ({
  hasMedia: selectors.media.hasAny(state),
  isLoading: selectors.media.isLoading(state),
  media: selectors.media.getAll(state),
  progress: selectors.media.getProgress(state)
});

/**
 * Map dispatch to props
 * @param {Object} dispatch
 * @return {Object}
 */
const mapDispatch = (dispatch) => ({
  actions: {
    media: bindActionCreators(actions.media, dispatch),
    snackbar: bindActionCreators(actions.snackbar, dispatch)
  }
});

export default connect(mapState, mapDispatch)(Media);
