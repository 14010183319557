import axios from 'axios';
import { combineReducers } from 'redux';
import { normalize } from 'normalizr';
import { mediaTypes as types } from 'state/actions';
import schema from 'state/schema';
import { CREATED_AT } from 'constants.js';

/**
 * Sets state/media/byIdReducer object.
 * @param {Object} [state={}] - The existing items. If no item or during initialization, use an empty object.
 * @param {Object} action - Contains the fetched media as JSON.
 *  {Array} - The new / unchanged items. Array of JSON. If fetch failed, won't change the existing item array.
 */
const byIdReducer = (state = {}, action) => {
  switch (action.type) {
    case types.CLEAR:
      return {};
    case types.FETCH_SUCCESS:
      return { ...action.payload.entities.media };
    case types.UPLOAD_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.media
      };
    case types.DELETE: {
      const { [action.payload.mediaId]: omit, ...remain } = state;
      return remain;
    }
    case types.DELETE_FAILED:
      return {
        ...state,
        [action.payload.id]: {
          ...action.payload
        }
      };
    default:
      return state;
  }
};

/**
 * Sets state/media/error. Shared among FETCH and UPLOAD. DELETE has its own error.
 * @param {Object|null} [state=null] - The error message. null for no error.
 * @param {Object} action
 * @return {Object|null} - The new error message. null for no error.
 */
const errorReducer = (state = null, action) => {
  switch (action.type) {
    case types.DELETE_FAILED:
    case types.FAILED:
    case types.UPLOAD_FAILED:
      return action.error;
    case types.FETCH_SUCCESS:
    case types.UPLOAD_SUCCESS:
      return null;
    default:
      return state;
  }
};

/**
 * Whenever we delete or restore, we don't change the items inside byId. We only change the ids here.
 * @param {Number[]} state - An array of ids
 * @param {Object} action
 */
const idsReducer = (state = [], action) => {
  switch (action.type) {
    case types.CLEAR:
      return [];
    case types.FETCH_SUCCESS:
      return action.payload.result;
    case types.UPLOAD_SUCCESS:
      return [...state, action.payload.result];
    case types.DELETE:
      return state.filter((id) => id !== action.payload.mediaId);
    case types.DELETE_FAILED:
      // TODO: Handle the order, instead of putting it right at the back.
      return [...state, action.payload.id];
    default:
      return state;
  }
};

/**
 * Sets state/media/isLoading. Will use this property to show/hide a spinner. All the "SUCCESS" events will be fired by the browser about
 * 1 second after the progress (see below) reaches 100, which is perfect because we don't want the spinner to disappear right after it reaches 100%.
 * @param {Boolean} [state=false] - Whether a fetch or upload is going on right now.
 * @param {Object} action
 * @return {Boolean} - true for is talking to the server, false for not.
 */
const isLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case types.FETCH:
    case types.UPLOAD:
      return true;
    case types.FETCH_SUCCESS:
    case types.UPLOAD_FAILED:
    case types.UPLOAD_SUCCESS:
    case types.FAILED:
      return false;
    default:
      return state;
  }
};

/**
 * Tracks the uploading/downloading progress.
 * @param {Number} [state=0]
 * @param {Object} action - Its payload is the progress number.
 * @return {Number} - The new progress.
 */
const progressReducer = (state = 0, action) => {
  switch (action.type) {
    case types.FETCH:
    case types.UPLOAD:
      return 0;
    case types.PROGRESS_UPDATED: // For downloading, it seems no matter how I throttle, the browser only fires a 100 event.
      return action.payload;
    case types.FAILED:
    case types.FETCH_SUCCESS:
    case types.UPLOAD_FAILED:
    case types.UPLOAD_SUCCESS:
      return 100;
    default:
      return state;
  }
};

/**
 * Change how to sort media. For now we can sort by create time or name.
 * @param {String} state
 * @param {Object} action
 * @return {String}
 */
const sortByReducer = (state = CREATED_AT, action) => {
  if (action.type === types.SORT) {
    return action.payload;
  }

  return state;
};

export default combineReducers({
  byId: byIdReducer,
  error: errorReducer,
  ids: idsReducer,
  isLoading: isLoadingReducer,
  progress: progressReducer,
  sortBy: sortByReducer
});

/**
 * Action dispatched when deleting a media asset fails.
 * @param {Object} error - Potentially non-serializable error message
 * @param {Number} mediaId - The mediaID planned to be deleted but failed
 */
const deleteFailed = (error, deletedMedia) => ({
  type: types.DELETE_FAILED,
  error,
  payload: deletedMedia
});

/**
 * Action dispatched when deleting a media asset succeeds.
 * @param {Object} response
 * @return {Object}
 */
const deleteSuccess = (response, deletedMedia) => ({
  type: types.DELETE_SUCCESS,
  payload: {
    ...response,
    userMessage: `File deleted: ${deletedMedia.filename}`
  }
});

/**
 * Action creator for all failed actions. The error can be a non-serializable error causing console errors.
 * That error won't break the application though.
 * @param {*} error
 */
const failed = (error) => ({
  type: types.FAILED,
  error
});

/**
 * Action creator for fetching all media belong to a site.
 * @param {Object} payload
 */
const fetchBySiteIdSuccess = (response) => ({
  type: types.FETCH_SUCCESS,
  payload: normalize(response, [schema.media])
});

/**
 * Action creator for updating downloading/uploading progress. Spinner/loader need this to display progress.
 * On faster network (or localhost), the browser might only fire 1 event with 100% progress.
 * @param {Number} progress - Calculated by getNetworkProgress.
 * @return {Object}
 */
const progressUpdated = (progress) => ({
  type: types.PROGRESS_UPDATED,
  payload: progress
});

/**
 * An action for upload failure.
 * @param {Object} error
 * @return {Object}
 */
const uploadFailed = (error) => ({
  type: types.UPLOAD_FAILED,
  error
});

/**
 * An action for upload success.
 * @param {Object} response
 * @return {Object}
 */
const uploadSuccess = (response) => {
  const payload = normalize(response, schema.media);
  payload.userMessage = `File Uploaded: ${response.filename}`;
  return {
    type: types.UPLOAD_SUCCESS,
    payload
  };
};

/**
 * Clears media in state.
 * @return {Object}
 */
export const clear = () => ({
  type: types.CLEAR
});

/**
 * Exported thunk for deleting a media by specifying its siteId and mediaId.
 * @param {Number} siteId - For now the application only supports 1 site.
 * @param {Number} mediaId
 * @return {Promise}
 */
export const deleteById = (siteId, mediaId) => (dispatch, getState) => {
  const payload = {
    mediaId
  };

  const deletedMedia = getState().media.byId[mediaId];

  dispatch({ type: types.DELETE, payload });

  return axios
    .delete(`/sites/${siteId}/assets/${mediaId}`)
    .then((response) => dispatch(deleteSuccess(response, deletedMedia)))
    .catch((error) => dispatch(deleteFailed(error, deletedMedia)));
};

/**
 * Utility function used for thunks that reports progress.
 * @param {Function} dispatch
 * @param {Object} actionCreator
 * @return {Function}
 */
const broadcastProgress = (dispatch, actionCreator) => (progressEvent) => {
  const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
  dispatch(actionCreator(percentCompleted));
};

/**
 * Exported thunk for fetching all media belong to a site.
 * @param {Number} siteId
 * @return {Promise}
 */
export const fetchBySiteId = (siteId) => (dispatch) => {
  dispatch({ type: types.FETCH });

  return axios
    .get(`/sites/${siteId}/assets`, {
      onDownloadProgress: broadcastProgress(dispatch, progressUpdated)
    })
    .then(({ data }) => dispatch(fetchBySiteIdSuccess(data)))
    .catch((error) => dispatch(failed(error)));
};

/**
 * Action creator for setting a new sort key.
 * @param {String} newSortKey - Such as "createdAt", "filename", etc
 */
export const sort = (newSortKey) => ({
  type: types.SORT,
  payload: newSortKey
});

/**
 * Exported thunk for uploading a media to a site.
 * @param {Number} siteId - For now, the API only supports 1 site. So this value is likely to be 1 from React side.
 * @param {Object} payload - blob? Not sure how upload will work for now.
 * @return {Promise}
 */
export const upload = (siteId, payload) => (dispatch) => {
  dispatch({ type: types.UPLOAD });

  const formData = new FormData();
  formData.append('file', payload);

  return axios
    .post(`/sites/${siteId}/assets`, formData, {
      onUploadProgress: broadcastProgress(dispatch, progressUpdated)
    })
    .then(({ data }) => dispatch(uploadSuccess(data)))
    .catch((error) => dispatch(uploadFailed(error)));
};
