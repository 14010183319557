import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './SubNavigation.module.scss';

const SubNavigation = ({ children, fixed, vertical }) => (
  <div
    className={classNames(styles.container, {
      [styles.fixed]: fixed,
      [styles.horizontal]: !vertical,
      [styles.vertical]: vertical
    })}
  >
    {children}
  </div>
);

SubNavigation.propTypes = {
  children: PropTypes.node.isRequired,
  fixed: PropTypes.bool,
  vertical: PropTypes.bool
};

SubNavigation.defaultProps = {
  fixed: false,
  vertical: false
};

export default SubNavigation;
