import * as Yup from 'yup';

const SettingsSchema = Yup.object().shape({
  content: Yup.object().shape({
    agentAnalytics: Yup.string(),
    calendarEnabled: Yup.boolean().required('Calendar enabled is required.'),
    calendarScript: Yup.string(),
    ratesDescription: Yup.string(),
    ratesEnabled: Yup.boolean().required('Rates enabled is required.'),
    ratesHeading: Yup.string().required('Rates heading is required.'),
    ratesShowLogos: Yup.boolean().required('Show lender logos is required.')
  })
});

export default SettingsSchema;
