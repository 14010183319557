import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { selectors } from 'state';

/**
 * Adds the current user to the passed component.
 * @param {Component} Component
 */
const withCurrentUser = (Component) => (props) => <Component {...props} />;

/**
 * Map state to props.
 * @param {Object} state
 * @return {Object}
 */
const mapState = (state) => ({
  currentUser: selectors.users.getCurrentUser(state)
});

export default compose(connect(mapState), withCurrentUser);
