import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'components/loader';
import styles from './Preview.module.scss';

/**
 * When `preview` is set to true for <Dropdown>, show this preview panel.
 * Show a spinner on top of the image if the image is being loaded.
 * TODO: How can we show a spinner only if the loading takes more than, say, 200ms?
 */
class Preview extends PureComponent {
  static propTypes = {
    url: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };

    this.onLoad = this.onLoad.bind(this);
    this.resetLoader = this.resetLoader.bind(this);

    this.image = new Image();
  }

  /**
   * Use the Image API to determine if the image is cached in the browser.
   * If not, and we're not currently showing the spinner, show the spinner.
   * onLoad is responsible for turning off the spinner.
   *
   */
  componentDidUpdate() {
    this.image.src = this.props.url;

    // Don't start right away, because the browser might take a very brief moment before firing onLoad even if the image is cached.
    // In that case, we don't want to flash the user with a gray overlay. Only show the overlay if the image loading takes more than 300ms.
    setTimeout(this.resetLoader, 300);
  }

  onLoad() {
    this.setState({
      isLoading: false
    });
  }

  resetLoader() {
    if (!this.state.isLoading && !this.image.complete) {
      this.setState({
        isLoading: true
      });
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.title}>{this.props.name}</div>
        <Loader.Spinner local show={this.state.isLoading} />
        <img alt={this.props.name} className={styles.img} onLoad={this.onLoad} src={this.props.url} />
      </div>
    );
  }
}

export default Preview;
