/**
 * Breadcrumbs for sites list page.
 * @type {Array}
 */
export const sitesCrumbs = [
  {
    name: 'Sites',
    url: '/sites'
  }
];

/**
 * Breadcrumbs for new site page.
 * @type {Array}
 */
export const newSiteCrumbs = [
  ...sitesCrumbs,
  {
    name: 'New Site',
    url: '/sites/new'
  }
];
