import auth0 from 'auth0-js';
import { actions, history, store } from 'state';
import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DOMAIN,
  AUTH0_ENTITLEMENT,
  AUTH0_LOGIN_URI,
  AUTH0_LOGOUT_URI,
  INTERCOM_APP_ID
} from 'constants.js';

const DEFAULT_LOGGED_IN = '/sites';
const UNAUTHORIZED_LOGGED_IN = '/register';
const REQUESTED_SCOPES = 'openid profile';
const RESPONSE_TYPE = 'token id_token';

/**
 * Handle redirection after login.
 * @param {Array} entitlements
 * @return {Void}
 */
function handleRedirect(entitlements) {
  if (entitlements.includes(AUTH0_ENTITLEMENT)) {
    const next = localStorage.getItem('next');
    localStorage.removeItem('next');
    if (next) {
      // Redirect to last page visited.
      history.replace(next);
    } else {
      // Redirect to default login page.
      history.replace(DEFAULT_LOGGED_IN);
    }
  } else {
    // Redirect to page for unauthorized users.
    history.replace(UNAUTHORIZED_LOGGED_IN);
  }
}

/**
 * Sets the Intercom session.
 * @param {Object} authResult
 * @return {Void}
 */
function setIntercomSession(authResult) {
  window.Intercom('update', {
    name: `${authResult.idTokenPayload.given_name} ${authResult.idTokenPayload.family_name}`,
    user_id: authResult.idTokenPayload.sub
  });
}

export default class Auth0 {
  /**
   * The bearer token used for API authorization. JWT.
   * https://auth0.com/docs/tokens/overview-access-tokens
   * @memberof Auth0
   */
  accessToken;

  /**
   * A timestamp indicating the session expire time.
   * @memberof Auth0
   */
  expiresAt;

  /**
   * A JWT that contains user profile information (like the user's name, email, and so forth),
   * represented in the form of claims. These claims are statements about the user,
   * which can be trusted if the consumer of the token can verify its signature.
   * https://auth0.com/docs/tokens/id-token
   * @memberof Auth0
   */
  idToken;

  /**
   * An object for user profile, containing things like name, picture, updatedAt, etc.
   * https://auth0.com/docs/quickstart/spa/vanillajs/02-user-profile
   * @memberof Auth0
   */
  userProfile;

  constructor() {
    this.auth0 = new auth0.WebAuth({
      audience: AUTH0_AUDIENCE,
      clientID: AUTH0_CLIENT_ID,
      domain: AUTH0_DOMAIN,
      redirectUri: AUTH0_LOGIN_URI,
      responseType: RESPONSE_TYPE,
      scope: REQUESTED_SCOPES
    });

    this.handleLogin = this.handleLogin.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.setSession = this.setSession.bind(this);
  }

  /**
   * Handles Auth0 login callback process and redirects to next page.
   *
   * @param {string} hash
   */
  handleLogin(hash) {
    this.auth0.parseHash({ hash }, (_err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult)
          .then(() => handleRedirect(authResult.idTokenPayload['https://tangofinancial.ca/entitlements']))
          .then(() => setIntercomSession(authResult));
      } else {
        this.logout();
      }
    });
  }

  /**
   * Returns boolean that indicates if user is logged in.
   *
   * @return {Boolean}
   */
  isAuthenticated() {
    return new Date().getTime() < this.expiresAt;
  }

  /**
   * Sends user to Auth0 Universal Login page to being authentication process.
   */
  login() {
    // Clear Intercom session cookie.
    window.Intercom('shutdown');
    window.Intercom('boot', { app_id: INTERCOM_APP_ID });

    this.auth0.authorize();
  }

  /**
   * Logs out the user and redirects to home page.
   */
  logout() {
    this.accessToken = null;
    this.expiresAt = null;
    this.idToken = null;

    // Clear user profile.
    this.userProfile = null;
    store.dispatch(actions.users.clearCurrentUser());

    // Clear axios Authorization header.
    window.axios.defaults.headers.common.Authorization = null;

    // Clear Intercom session cookie.
    window.Intercom('shutdown');
    window.Intercom('boot', { app_id: INTERCOM_APP_ID });

    this.auth0.logout({
      returnTo: AUTH0_LOGOUT_URI,
      clientID: AUTH0_CLIENT_ID
    });
  }

  /**
   * Attempts to renew a session by checking with the Auth0 server for an active session.
   */
  renewSession() {
    this.auth0.checkSession({}, (_err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult)
          .then(() => handleRedirect(authResult.idTokenPayload['https://tangofinancial.ca/entitlements']))
          .then(() => setIntercomSession(authResult));
      } else {
        this.logout();
      }
    });
  }

  /**
   * Stores session information in memory.
   * @param {Object} authResult
   * @return {Promise}
   */
  setSession(authResult) {
    // Set the time that the Access Token will expire at.
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.expiresAt = authResult.expiresIn * 1000 + new Date().getTime();

    // Set axios Authorization header to allow requests to Dispatch API.
    window.axios.defaults.headers.common.Authorization = `Bearer ${this.accessToken}`;

    // Add fallback properties from Auth0 in case Passport request fails.
    const fallback = {
      firstName: authResult.idTokenPayload.given_name,
      imageUrl: authResult.idTokenPayload.picture,
      lastName: authResult.idTokenPayload.family_name,
      organizationId: null,
      role: null
    };

    // Consolidate idTokenPayload values that will be added to current user in state.
    const auth = {
      authId: authResult.idTokenPayload.sub,
      entitlements: authResult.idTokenPayload['https://tangofinancial.ca/entitlements'],
      ...fallback
    };

    // Dispatch action to fetch current user from Passport API.
    return store.dispatch(actions.users.fetchCurrent(auth));
  }
}
