import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import schema from 'state/schema';
import { CREATED_AT, FILENAME } from 'constants.js';

/**
 * Get All Media from State to be used in a memotized selector.
 * @param {Object} state
 * @return {Object} An object with request hash as keys, request content as values. Multiple media.
 */
const getAllMediaFromState = (state) => state.media.byId;

/**
 * Find out how the broker wants to sort his media.
 * @param {Object} state
 * @return {String} - The sort key we want to use
 */
export const getSortKey = (state) => state.media.sortBy;

/**
 * Get all the media from the state, then use the state.user.sortBy value to sort.
 * Since we're only using media1[sortkey] - media2[sortkey], it should work for createdAt and filename; however,
 * for future sorting selections, we might need more complicated sorting functions.
 * @param {Function[]} - An array of memoized input selectors
 * @param {Function} - The transform function.
 * @return {media[]} A sorted array of media.
 */
export const getAll = createSelector([getSortKey, getAllMediaFromState], (sortKey, media) =>
  Object.keys(media)
    .map((id) => denormalize(media[id], schema.media, { media }))
    .sort((media1, media2) => {
      const sortValue1 = media1[sortKey];
      const sortValue2 = media2[sortKey];

      switch (sortKey) {
        case CREATED_AT:
          // Newest one first.
          return Date.parse(sortValue2) - Date.parse(sortValue1);
        case FILENAME:
          // Can't do subtractions on strings directly, but we can still compare using < and >.
          return sortValue1 < sortValue2 ? -1 : 1;
        default:
          // If sort key is unrecognized, don't change order.
          return 0;
      }
    })
);

/**
 * @param {Function[]} - An array of memoized input selectors
 * @param {Function} - The transform function.
 * @return {media[]} A sorted array of media images.
 */
export const getAllImages = createSelector([getAll], (media) =>
  media.filter((file) => file.mimeType.includes('image'))
);

/**
 * Get a single request.
 * @param {Object} state
 * @return {Object} An object with the request hash as the key, and request content as the value. A single request.
 */
export const getById = (state, id) => state.media.byId[id] || null;

/**
 * Get the most recent error from state, which is a JavaScript Exception.
 * Don't use this to pull error message to inform the user. See getErrorMessage below.
 * @param {Object} state
 * @return {Exception|null}
 */
export const getError = (state) => state.media.error;

/**
 * Get the most recent error message from state. This is not the generic JavaScript message, but the hand
 * crafted one from the server. Use this selector if we want to show that message on the UI.
 * @param {Object|null} state
 * @return {String}
 */
export const getErrorMessage = (state) => {
  if (!getError(state)) return null;

  try {
    return state.media.error.response.data.message;
  } catch (e) {
    return "Oops! something went wrong, and we can't perform your operation. We apologize for the inconvenience; please try again later!";
  }
};

/**
 * Get the download/upload progress. This is computed based on the browser's native ProgressEvent.
 * @param {Object} state
 * @return {Number} - An integer between 0 & 100.
 */
export const getProgress = (state) => state.media.progress;

/**
 * Check if there is currently an error message.
 * @param {Object} state
 * @return {Boolean} Whether there is an error message.
 */
export const hasError = (state) => state.media.error !== null;

/**
 * Returns true if state contains any media.
 * @param {Object} state
 * @return {Boolean}
 */
export const hasAny = (state) => Object.keys(state.media.byId).length > 0;

/**
 * Check if we're sending or retrieving data with the server. Show a Spinner or ProgressRing.
 * @param {Object} state
 * @return {Boolean} Whether the client is busy.
 */
export const isLoading = (state) => state.media.isLoading;
