import * as breadcrumbs from './breadcrumbs';
import * as media from './media';
import * as permissions from './permissions';
import * as sites from './sites';
import * as snackbar from './snackbar';
import * as users from './users';

export default {
  breadcrumbs,
  media,
  permissions,
  sites,
  snackbar,
  users
};
