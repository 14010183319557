import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actions, selectors } from 'state';
import { Actions, Subcontent } from 'components/layout';
import { Menu } from 'components/menu';
import { Button, RouterButton } from 'components/button';
import { Link } from 'components/navigation';
import { Status } from 'components/status';
import { Table } from 'components/table';
import { formatDate } from 'transformations';
import { sitesCrumbs } from './breadcrumbs';

/**
 * Sites page located at the /sites route.  A broker can view their sites from this page.
 * @class SitesList
 * @extends {Component}
 */
class SitesList extends Component {
  /**
   * SitesList prop types
   * @type {Object}
   */
  static propTypes = {
    actions: PropTypes.shape({
      breadcrumbs: PropTypes.shape({
        update: PropTypes.func
      }),
      sites: PropTypes.shape({
        destroy: PropTypes.func,
        fetchAll: PropTypes.func,
        update: PropTypes.func
      }),
      snackbar: PropTypes.shape({
        clear: PropTypes.func
      })
    }).isRequired,
    canCreateSites: PropTypes.bool.isRequired,
    canDeleteSites: PropTypes.bool.isRequired,
    hasAnySites: PropTypes.bool.isRequired,
    sites: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.shape({
          title: PropTypes.string
        }),
        createdAt: PropTypes.string,
        theme: PropTypes.string,
        updatedAt: PropTypes.string,
        url: PropTypes.string
      })
    ).isRequired
  };

  /**
   * Constructor
   * @return {Void}
   */
  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    };
    this.destroySite = this.destroySite.bind(this);
    this.disableSite = this.disableSite.bind(this);
    this.enableSite = this.enableSite.bind(this);
  }

  /**
   * ComponentDidMount lifecycle method
   * @return {Void}
   */
  componentDidMount() {
    const { breadcrumbs, sites, snackbar } = this.props.actions;
    breadcrumbs.update(sitesCrumbs);
    sites.fetchAll().then(({ error }) => {
      if (error) {
        snackbar.clear();
      }
      this.setState({ loaded: true });
    });
  }

  /**
   * Permanently deletes a site.
   * @param {Number} siteId
   * @return {Void}
   */
  destroySite(siteId) {
    this.props.actions.sites.destroy(siteId).then(() => this.props.actions.snackbar.clear());
  }

  /**
   * Disables a site by ID via update action.
   * @param {Number} siteId
   * @return {Void}
   */
  disableSite(siteId) {
    const payload = { enabled: false };
    this.props.actions.sites.update(siteId, payload).then(() => this.props.actions.snackbar.clear());
  }

  /**
   * Enables a site by ID via update action.
   * @param {Number} siteId
   * @return {Void}
   */
  enableSite(siteId) {
    const payload = { enabled: true };
    this.props.actions.sites.update(siteId, payload).then(() => this.props.actions.snackbar.clear());
  }

  /**
   * Renders the SitesList component
   * @return {Object}
   */
  render() {
    const { canCreateSites, canDeleteSites, hasAnySites, sites } = this.props;
    const { loaded } = this.state;
    return (
      loaded && (
        <Subcontent>
          <Actions>
            {canCreateSites ? (
              <RouterButton icon="add" intent="primary" to="/sites/new">
                New Site
              </RouterButton>
            ) : (
              <Button disabled icon="add" intent="primary" onClick={() => undefined}>
                New Site
              </Button>
            )}
          </Actions>
          {hasAnySites ? (
            <Table>
              <Table.Head>
                <Table.TextHeaderCell flex={2}>Title</Table.TextHeaderCell>
                <Table.TextHeaderCell flex={2}>URL</Table.TextHeaderCell>
                <Table.TextHeaderCell flex={1}>Created</Table.TextHeaderCell>
                <Table.TextHeaderCell flex={1}>Last Updated</Table.TextHeaderCell>
                <Table.TextHeaderCell flex={1}>Disabled</Table.TextHeaderCell>
                <Table.TextHeaderCell flex={1}>Actions</Table.TextHeaderCell>
              </Table.Head>
              <Table.Body>
                {sites.map(({ id, content, createdAt, disabledAt, updatedAt, url }) => (
                  <Table.Row key={id}>
                    <Table.TextCell flex={2}>
                      <Link to={`/sites/${id}/update/about`}>{content.title}</Link>
                    </Table.TextCell>
                    <Table.TextCell flex={2}>
                      {disabledAt ? (
                        url
                      ) : (
                        <Link external to={url}>
                          {url}
                        </Link>
                      )}
                    </Table.TextCell>
                    <Table.TextCell flex={1}>{formatDate(createdAt)}</Table.TextCell>
                    <Table.TextCell flex={1}>{formatDate(updatedAt)}</Table.TextCell>
                    <Table.TextCell flex={1}>{disabledAt ? formatDate(disabledAt) : '-'}</Table.TextCell>
                    <Table.TextCell flex={1}>
                      <Menu>
                        <Menu.Group>
                          <Menu.RouterItem icon="edit" to={`/sites/${id}/update/about`}>
                            Update Site
                          </Menu.RouterItem>
                          {disabledAt ? (
                            <Menu.Item icon="tick-circle" intent="success" onSelect={() => this.enableSite(id)}>
                              Enable Site
                            </Menu.Item>
                          ) : (
                            <Menu.Item icon="disable" intent="danger" onSelect={() => this.disableSite(id)}>
                              Disable Site
                            </Menu.Item>
                          )}
                          {canDeleteSites && (
                            <Menu.Item icon="trash" intent="danger" onSelect={() => this.destroySite(id)}>
                              Delete Site
                            </Menu.Item>
                          )}
                        </Menu.Group>
                      </Menu>
                    </Table.TextCell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          ) : (
            <Status asset="undraw/choice.svg" heading="You don't have any sites." />
          )}
        </Subcontent>
      )
    );
  }
}

/**
 * Map state to props
 * @param {Object} state
 * @return {Object}
 */
const mapState = (state) => ({
  canCreateSites: selectors.permissions.canCreateSites(state),
  canDeleteSites: selectors.permissions.canDeleteSites(state),
  hasAnySites: selectors.sites.hasAny(state),
  sites: selectors.sites.getAll(state)
});

/**
 * Map dispatch to props
 * @param {Function} dispatch
 * @return {Object}
 */
const mapDispatch = (dispatch) => ({
  actions: {
    breadcrumbs: bindActionCreators(actions.breadcrumbs, dispatch),
    sites: bindActionCreators(actions.sites, dispatch),
    snackbar: bindActionCreators(actions.snackbar, dispatch)
  }
});

export default connect(mapState, mapDispatch)(SitesList);
