import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, FieldArray } from 'formik';
import styles from 'components/form/formik/FormikGroup.module.scss';
import { Actions } from 'components/layout';
import { Button } from 'components/button';
import { FormikSection as Section } from 'components/form/formik';
import { Link } from 'components/navigation';
import { selectors } from 'state';
import { resourceDefaults } from './groupDefaults';

/**
 * Contains a repeating collection of field group for resources.
 * @function ResourcesGroup
 * @param {Object} props
 * @return {Object}
 */
const ResourcesGroup = ({ category, max, media, name, values }) => (
  <FieldArray name={name}>
    {(arrayHelpers) => (
      <Fragment>
        <Actions>
          <Button
            disabled={values && values.length >= max}
            type="button"
            icon="add"
            intent="primary"
            onClick={() => arrayHelpers.push({ ...resourceDefaults, resourceAssetId: media[0].id })}
          >
            {`Add ${category}`}
          </Button>
        </Actions>
        {values &&
          values.length > 0 &&
          values.map((value, index) => (
            /* eslint-disable react/no-array-index-key */
            <div className={styles.repeater} key={index}>
              <Section.Pane>
                <Field name={`${name}.${index}.resourceName`} value={`${value}.resourceName`}>
                  {() => (
                    <Section.Input
                      id={`${name}.${index}.resourceName`}
                      label="Name"
                      name={`${name}.${index}.resourceName`}
                    />
                  )}
                </Field>
                <Field name={`${name}.${index}.resourceDescription`} value={`${value}.resourceDescription`}>
                  {() => (
                    <Section.TextArea
                      id={`${name}.${index}.resourceDescription`}
                      label="Description (Optional)"
                      name={`${name}.${index}.resourceDescription`}
                    />
                  )}
                </Field>
              </Section.Pane>
              <Section.Pane>
                <Field name={`${name}.${index}.resourceAssetId`} value={`${value}.resourceAssetId`}>
                  {() => (
                    <Section.Select
                      display="filename"
                      id={`${name}.${index}.resourceAssetId`}
                      label="File"
                      name={`${name}.${index}.resourceAssetId`}
                      options={media}
                      value="id"
                    />
                  )}
                </Field>
                {value.resourceUrl && (
                  <Link external to={value.resourceUrl}>
                    {value.resourceUrl}
                  </Link>
                )}
              </Section.Pane>
              <Actions>
                <Button icon="trash" intent="danger" onClick={() => arrayHelpers.remove(index)} type="button">
                  Remove
                </Button>
              </Actions>
            </div>
          ))}
      </Fragment>
    )}
  </FieldArray>
);

/**
 * Map state to props
 * @param {Object} state
 * @return {Object}
 */
const mapState = (state) => ({
  media: selectors.media.getAll(state)
});

/**
 * ResourcesGroup prop types
 * @type {Object}
 */
ResourcesGroup.propTypes = {
  category: PropTypes.string,
  name: PropTypes.string.isRequired,
  max: PropTypes.string,
  media: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      filename: PropTypes.string,
      url: PropTypes.string
    })
  ),
  values: PropTypes.arrayOf(
    PropTypes.shape({
      resourceAssetId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      resourceDescription: PropTypes.string,
      resourceName: PropTypes.string.isRequired,
      resourceUrl: PropTypes.string
    })
  )
};

/**
 * ResourcesGroup default props
 * @type {Object}
 */
ResourcesGroup.defaultProps = {
  category: 'Item',
  max: '6',
  media: [],
  values: []
};

export default connect(mapState)(ResourcesGroup);
