import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'evergreen-ui';

const Row = ({ children, ...props }) => <Table.Row {...props}>{children}</Table.Row>;

Row.propTypes = {
  children: PropTypes.node.isRequired
};

export default Row;
