// Inspired by https://css-tricks.com/building-progress-ring-quickly/#article-header-id-8
// A determinate loading ring. If we want a non-determinate version, use the Spinner component.
import React from 'react';
import PropTypes from 'prop-types';
import styles from './ProgressRing.module.scss';

const colors = {
  green: '#00E676',
  white: 'white'
};

const ProgressRing = ({ progress, radius, show, stroke }) => {
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;

  const getCircleByColor = (color) => (
    <circle
      className={styles.circle}
      cx={radius}
      cy={radius}
      fill="transparent"
      r={normalizedRadius}
      stroke={color}
      strokeDasharray={`${circumference} ${circumference}`}
      strokeWidth={stroke}
      style={{ strokeDashoffset }}
    />
  );

  /**
   * Sometimes the progress is so fast, we jump from 0 to 100 instantly, and the client will see the gray overlay flash for a brief second. Not good.
   * To solve this issue, we only allow the ProgressRing component to show up if the progress is not 0.
   */
  return (
    show &&
    progress > 0 && (
      <div className={styles.overlay}>
        <svg height={radius * 2} width={radius * 2} className={styles.circleContainer}>
          {progress < 100 ? getCircleByColor(colors.white) : getCircleByColor(colors.green)}
        </svg>
      </div>
    )
  );
};

ProgressRing.propTypes = {
  progress: PropTypes.number.isRequired,
  radius: PropTypes.number,
  show: PropTypes.bool.isRequired,
  stroke: PropTypes.number
};

ProgressRing.defaultProps = {
  radius: 60,
  stroke: 4
};

export default ProgressRing;
