import React from 'react';
import PropTypes from 'prop-types';
import { FormikSection as Section } from 'components/form/formik';

/**
 * A form for updating contact information.
 * @function ContactInformationSection
 * @param {Object} props
 * @return {Object}
 */
const ContactInformationSection = ({ values }) => (
  <Section>
    <h3>Contact Information</h3>
    <Section.Pane>
      <Section.Input id="agentFirstName" label="First Name" name="content.agentFirstName" />
      <Section.Input id="agentLastName" label="Last Name" name="content.agentLastName" />
      <Section.Input id="agentEmail" label="Email Address" name="content.agentEmail" />
      <Section.Array
        category="Licence"
        id="agentLicences"
        label="Broker Licence Number(s)"
        name="content.agentLicences"
        values={values.content.agentLicences}
      />
    </Section.Pane>
    <Section.Pane>
      <Section.Input
        id="agentTitle"
        label="Position Title"
        name="content.agentTitle"
        placeholder="e.g., Mortgage Broker"
      />
      <Section.Input id="agentAddress" label="Office Address" name="content.agentAddress" />
      <Section.Input id="agentPhone" label="Phone Number" name="content.agentPhone" />
      <Section.Checkbox
        checked={values.content.displayEngageBadge}
        id="displayEngageBadge"
        label={'Show "Powered By Engage Apps" Badge'}
        name="content.displayEngageBadge"
      />
    </Section.Pane>
  </Section>
);

/**
 * ContactInformationSection prop types
 * @type {Object}
 */
ContactInformationSection.propTypes = {
  values: PropTypes.shape({
    content: PropTypes.shape({
      agentLicenses: PropTypes.arrayOf(PropTypes.string),
      displayEngageBadge: PropTypes.bool
    })
  }).isRequired
};

export default ContactInformationSection;
