export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const APP_NAME = process.env.REACT_APP_APP_NAME;
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const CLIENT_DOMAIN = process.env.REACT_APP_CLIENT_DOMAIN;
export const LOGO_URL = process.env.REACT_APP_LOGO_URL;

// Auth0 configuration.
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID;
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN;
export const AUTH0_ENTITLEMENT = process.env.REACT_APP_AUTH0_ENTITLEMENT;
export const AUTH0_LOGIN_URI = process.env.REACT_APP_AUTH0_LOGIN_URI;
export const AUTH0_LOGOUT_URI = process.env.REACT_APP_AUTH0_LOGOUT_URI;

// Intercom app ID.
export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

// Passport API Base URL.
export const PASSPORT_API = process.env.REACT_APP_PASSPORT_API;

// Sentry configuration.
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const SENTRY_ENVIRONMENT = process.env.REACT_APP_SENTRY_ENVIRONMENT;

export const CREATED_AT = 'createdAt';
export const FILENAME = 'filename';
