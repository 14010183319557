const CLEAR = 'media/clear';
const DELETE = 'media/DELETE';
const DELETE_FAILED = 'media/DELETE_FAILED';
const DELETE_SUCCESS = 'media/DELETE_SUCCESS';
const FAILED = 'media/FAILED';
const FETCH = 'media/FETCH';
const FETCH_SUCCESS = 'media/FETCH_SUCCESS';
const PROGRESS_UPDATED = 'media/PROGRESS_UPDATED';
const SORT = 'media/SORT';
const UPLOAD = 'media/UPLOAD';
const UPLOAD_FAILED = 'media/UPLOAD_FAILED';
const UPLOAD_SUCCESS = 'media/UPLOAD_SUCCESS';

/**
 * Media action types.
 */
export default {
  CLEAR,
  DELETE,
  DELETE_FAILED,
  DELETE_SUCCESS,
  FAILED,
  FETCH,
  FETCH_SUCCESS,
  PROGRESS_UPDATED,
  SORT,
  UPLOAD,
  UPLOAD_FAILED,
  UPLOAD_SUCCESS
};
