import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'evergreen-ui';

const TextCell = ({ children, ...props }) => <Table.TextCell {...props}>{children}</Table.TextCell>;

TextCell.propTypes = {
  children: PropTypes.node.isRequired
};

export default TextCell;
