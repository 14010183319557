import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { Callback, PrivateRoute } from 'components/auth';
import { ErrorBoundary, NotFound } from 'components/errors';
import { history } from 'state';
import { Homepage, Register, Sites } from 'pages';
import { Intercom } from 'components/intercom';
import { INTERCOM_APP_ID } from 'constants.js';
import { Primary } from 'components/layout';
import styles from 'App.module.scss';

const App = () => (
  <Router history={history}>
    <div className={styles.root}>
      <ErrorBoundary>
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route path="/callback" component={Callback} />
          <PrivateRoute path="/register" component={Register} />
          <PrivateRoute path="/sites" component={Sites} />
          <Route
            render={() => (
              <Primary>
                <NotFound resource="Page" link="/sites" page="Sites" />
              </Primary>
            )}
          />
        </Switch>
      </ErrorBoundary>
    </div>
    <Intercom appId={INTERCOM_APP_ID} />
  </Router>
);

export default App;
