import React from 'react';
import PropTypes from 'prop-types';
import styles from 'components/form/formik/FormikSection.module.scss';
import { FormikSection as Section } from 'components/form/formik';
import { ResourcesGroup } from 'forms/groups';

/**
 * A form for updating resources.
 * @function ResourcesSection
 * @param {Object} props
 * @return {Object}
 */
const ResourcesSection = ({ values }) => (
  <Section>
    <div className={styles.heading}>
      <Section.Checkbox
        checked={values.content.resourcesEnabled}
        id="resourcesEnabled"
        label={<h3>Resources</h3>}
        name="content.resourcesEnabled"
      />
    </div>
    <ResourcesGroup category="Resource" max="6" name="content.resources" values={values.content.resources} />
  </Section>
);

/**
 * ResourcesSection prop types
 * @type {Object}
 */
ResourcesSection.propTypes = {
  values: PropTypes.shape({
    content: PropTypes.shape({
      resources: PropTypes.arrayOf(
        PropTypes.shape({
          resourceAssetId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          resourceDescription: PropTypes.string,
          resourceName: PropTypes.string.isRequired
        })
      ),
      resourcesEnabled: PropTypes.bool.isRequired
    })
  }).isRequired
};

export default ResourcesSection;
