import React from 'react';
import PropTypes from 'prop-types';
import mediaTypes from './mediaTypes';
import styles from './FiletypeIcon.module.scss';

/**
 * Derives a filetype from a MIME type.
 * @param {String} mediaType
 * @return {String}
 */
const getFiletypeFromMediatype = (mediaType) => {
  switch (mediaType) {
    case mediaTypes.WORD.DOC:
    case mediaTypes.WORD.DOCX:
      return 'word';
    case mediaTypes.PDF:
      return 'pdf';
    case mediaTypes.EXCEL.XLS:
    case mediaTypes.EXCEL.XLSX:
      return 'excel';
    case mediaTypes.POWERPOINT.PPT:
    case mediaTypes.POWERPOINT.PPTX:
      return 'powerpoint';
    case mediaTypes.IMAGE.BMP:
    case mediaTypes.IMAGE.GIF:
    case mediaTypes.IMAGE.JPEG:
    case mediaTypes.IMAGE.JPG:
    case mediaTypes.IMAGE.PNG:
    case mediaTypes.IMAGE.SVG:
    case mediaTypes.IMAGE.TIFF:
      return 'image';
    default:
      return 'text';
  }
};

/**
 * Displays a filetype icon based on MIME type.
 * @funcion Icon
 * @param {Object} props
 * @return {Object}
 */
const FiletypeIcon = ({ mediaType, size, variant }) => (
  <div className={styles.container} style={{ maxWidth: size }}>
    <img
      src={`https://assets.engageapps.ca/icons/filetypes/${variant}/${getFiletypeFromMediatype(mediaType)}.svg`}
      alt={`Filetype icon for ${mediaType}`}
    />
  </div>
);

/**
 * FiletypeIcon prop types
 * @type {Object}
 */
FiletypeIcon.propTypes = {
  mediaType: PropTypes.string.isRequired,
  size: PropTypes.number,
  variant: PropTypes.string
};

/**
 * FiletypeIcon default props
 * @type {Object}
 */
FiletypeIcon.defaultProps = {
  size: 25,
  variant: 'solid'
};

export default FiletypeIcon;
