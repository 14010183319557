import React from 'react';
import { FormikSection as Section } from 'components/form/formik';

/**
 * A form for updating analytic information.
 * @function AnalyticsSection
 * @return {Object}
 */
const AnalyticsSection = () => (
  <Section>
    <h3>Analytics</h3>
    <Section.Pane>
      <Section.Input
        id="agentAnalytics"
        label="Google Analytics Tracking ID"
        name="content.agentAnalytics"
        placeholder="e.g., UA-XXXXX-Y"
      />
    </Section.Pane>
  </Section>
);

export default AnalyticsSection;
