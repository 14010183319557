/**
 * Array of feature icons.
 * @type {Array}
 */
const featureIcons = [
  {
    display: 'Check',
    url: '/icons/check-solid.svg',
    value: 'fas fa-check'
  },
  {
    display: 'Clock',
    url: '/icons/clock-regular.svg',
    value: 'far fa-clock'
  },
  {
    display: 'Lock',
    url: '/icons/lock-solid.svg',
    value: 'fas fa-lock'
  },
  {
    display: 'Key',
    url: '/icons/key-solid.svg',
    value: 'fas fa-key'
  },
  {
    display: 'Home',
    url: '/icons/home-solid.svg',
    value: 'fas fa-home'
  },
  {
    display: 'Hourglass',
    url: '/icons/hourglass-regular.svg',
    value: 'far fa-hourglass'
  },
  {
    display: 'Handshake',
    url: '/icons/handshake-regular.svg',
    value: 'far fa-handshake'
  },
  {
    display: 'Credit Card',
    url: '/icons/credit-card-regular.svg',
    value: 'far fa-credit-card'
  },
  {
    display: 'Money',
    url: '/icons/money-bill-alt-regular.svg',
    value: 'far fa-money-bill-alt'
  },
  {
    display: 'Chart',
    url: '/icons/chart-line-solid.svg',
    value: 'fas fa-chart-line'
  },
  {
    display: 'Thumbs Up',
    url: '/icons/thumbs-up-regular.svg',
    value: 'far fa-thumbs-up'
  }
];

export default featureIcons;
