import * as Yup from 'yup';

/**
 * Yup validation schema for New Site form.
 * @type {Object}
 */
const NewSiteSchema = Yup.object().shape({
  content: Yup.object().shape({
    agentEmail: Yup.string()
      .email('Must be a valid email address.')
      .required('Email is required.'),
    agentFirstName: Yup.string().required('First name is required.'),
    agentLastName: Yup.string().required('Last name is required.'),
    agentTitle: Yup.string().required('Agent title is required.')
  }),
  name: Yup.string()
    .max('25', 'Site name must contain fewer than 25 characters.')
    .min('5', 'Site name must contain more than 5 characters.')
    .required('Site name is required.')
});

export default NewSiteSchema;
