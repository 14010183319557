import * as Yup from 'yup';

/**
 * Yup validation schema for Theme form
 * @type {Object}
 */
const ThemeSchema = Yup.object().shape({
  content: Yup.object().shape({
    appearanceColorScheme: Yup.string()
      .oneOf(['blue', 'green', 'red'])
      .required('Color scheme is required.'),
    heroDescription: Yup.string().required('Hero description is required.'),
    heroHeading: Yup.string().required('Hero heading is required.'),
    heroImageId: Yup.number()
      .positive('Hero image ID must be a positive integer.')
      .nullable(),
    linksApplyOnline: Yup.string().url('Apply Online must be a valid URL.'),
    title: Yup.string().required('Site Title is required.')
  }),
  theme: Yup.string()
    .oneOf(['engage-classic', 'engage-modern', 'engage-traditional'])
    .required('Theme is required.')
});

export default ThemeSchema;
