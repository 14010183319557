/**
 * <RouterItem> is a wrapper for Segment's <Menu.Item>; instead of using `onSelect`, we put it inside
 * a <Link> to navigate to another page. You can still pass in onSelect; it'll be called while the route is changing.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Menu } from 'evergreen-ui';
import styles from './RouterItem.module.scss';

const RouterItem = ({ children, to, ...props }) => (
  <Link to={to} className={styles.container}>
    <Menu.Item {...props}>{children}</Menu.Item>
  </Link>
);

RouterItem.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired
};

export default RouterItem;
