/**
 * Supported MIME types.
 * @type {Object}
 */
const mediaTypes = {
  EXCEL: {
    XLS: 'application/vnd.ms-excel',
    XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  },
  IMAGE: {
    BMP: 'image/bmp',
    GIF: 'image/gif',
    JPEG: 'image/jpeg',
    JPG: 'image/jpg',
    PNG: 'image/png',
    SVG: 'image/svg+xml',
    TIFF: 'image/tiff'
  },
  PDF: 'application/pdf',
  POWERPOINT: {
    PPT: 'application/vnd.ms-powerpoint',
    PPTX: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  },
  TEXT: 'text/plain',
  WORD: {
    DOC: 'application/msword',
    DOCX: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  }
};

export default Object.freeze(mediaTypes);
