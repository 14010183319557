import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { CLIENT_DOMAIN } from 'constants.js';
import { FormikForm as Form, FormikSection as Section } from 'components/form/formik';
import { NewSiteSchema } from './schemas';

/**
 * A redux-enabled form for creating a new site.
 * @function NewSiteForm
 * @param {Object} props
 * @return {Object}
 */
const NewSiteForm = ({ onSubmit }) => (
  <Formik
    initialValues={{
      content: {
        agentEmail: '',
        agentFirstName: '',
        agentLastName: '',
        agentTitle: ''
      },
      name: ''
    }}
    validationSchema={NewSiteSchema}
    onSubmit={onSubmit}
  >
    {({ dirty, isValid }) => (
      <Form buttonText="Create Site" dirty={dirty} isValid={isValid}>
        <Section>
          <Section.Pane>
            <Section.Input id="agentFirstName" label="First Name" name="content.agentFirstName" />
            <Section.Input id="agentLastName" label="Last Name" name="content.agentLastName" />
            <Section.Input id="agentEmail" label="Email Address" name="content.agentEmail" />
          </Section.Pane>
          <Section.Pane>
            <Section.Input
              id="agentTitle"
              label="Position Title"
              name="content.agentTitle"
              placeholder="e.g., Mortgage Broker"
            />
            <Section.Input
              id="name"
              followText={CLIENT_DOMAIN}
              label="Site Name (subdomain)"
              name="name"
              placeholder="e.g., johndoe"
            />
          </Section.Pane>
        </Section>
      </Form>
    )}
  </Formik>
);

/**
 * NewSiteForm propTypes
 * @type {Object}
 */
NewSiteForm.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default NewSiteForm;
