import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'evergreen-ui';
import styles from './Head.module.scss';

const Head = ({ children, ...props }) => (
  <Table.Head className={styles.container} {...props}>
    {children}
  </Table.Head>
);

Head.propTypes = {
  children: PropTypes.node.isRequired
};

export default Head;
