import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'evergreen-ui';

const Item = ({ children, ...props }) => <Menu.Item {...props}>{children}</Menu.Item>;

Item.propTypes = {
  children: PropTypes.node.isRequired
};

export default Item;
