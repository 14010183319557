/**
 * A transparent button with only an icon inside. We're not using our own <Button> because its styling
 * doesn't match the specific need of IconButton; don't want to clutter <Button>'s style anymore.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components/icon';
import styles from './IconButton.module.scss';

const IconButton = ({ innerRef, intent, name, onClick }) => (
  <button className={styles.container} onClick={onClick} ref={innerRef} type="button">
    <Icon iconSize={20} intent={intent} name={name} />
  </button>
);

IconButton.propTypes = {
  /**
   * Evergreen's Popover needs this
   */
  innerRef: PropTypes.func,

  intent: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

IconButton.defaultProps = {
  onClick: null,
  innerRef: null,
  intent: 'default'
};

export default IconButton;
