const CREATE = 'sites/CREATE';
const CREATE_FAILED = 'sites/CREATE_FAILED';
const CREATE_FAILED_422 = 'sites/CREATE_FAILED_422';
const CREATE_SUCCESS = 'sites/CREATE_SUCCESS';
const DESTROY = 'sites/DESTROY';
const DESTROY_FAILED = 'sites/DESTROY_FAILED';
const DESTROY_SUCCESS = 'sites/DESTROY_SUCCESS';
const FETCH = 'sites/FETCH';
const FETCH_BY_ID = 'sites/FETCH_BY_ID';
const FETCH_BY_ID_FAILED = 'sites/FETCH_BY_ID_FAILED';
const FETCH_BY_ID_SUCCESS = 'sites/FETCH_BY_ID_SUCCESS';
const FETCH_FAILED = 'sites/FETCH_FAILED';
const FETCH_SUCCESS = 'sites/FETCH_SUCCESS';
const UPDATE = 'sites/UPDATE';
const UPDATE_FAILED = 'sites/UPDATE_FAILED';
const UPDATE_FAILED_422 = 'sites/UPDATE_FAILED_422';
const UPDATE_SUCCESS = 'sites/UPDATE_SUCCESS';

/**
 * Sites action types.
 * @type {Object}
 */
export default {
  CREATE,
  CREATE_FAILED,
  CREATE_FAILED_422,
  CREATE_SUCCESS,
  DESTROY,
  DESTROY_FAILED,
  DESTROY_SUCCESS,
  FETCH,
  FETCH_BY_ID,
  FETCH_BY_ID_FAILED,
  FETCH_BY_ID_SUCCESS,
  FETCH_FAILED,
  FETCH_SUCCESS,
  UPDATE,
  UPDATE_FAILED,
  UPDATE_FAILED_422,
  UPDATE_SUCCESS
};
