import React from 'react';
import PropTypes from 'prop-types';
import { FieldArray } from 'formik';
import Label from 'components/form/Label';
import FormikInputRepeater from './FormikInputRepeater';
import styles from './FormikGroup.module.scss';

/**
 * Container component for a label, input array and error message. For use in Formik forms.
 * @function FormikArrayGroup
 * @param {Object} props
 * @return {Object}
 */
const FormikArrayGroup = ({ category, id, label, max, name, values }) => (
  <div className={styles.container}>
    <Label htmlFor={id}>{label}</Label>
    <FieldArray name={name}>
      {(arrayHelpers) => (
        <FormikInputRepeater arrayHelpers={arrayHelpers} category={category} max={max} name={name} values={values} />
      )}
    </FieldArray>
  </div>
);

/**
 * FormikArrayGroup prop types
 * @type {Object}
 */
FormikArrayGroup.propTypes = {
  category: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  max: PropTypes.string,
  name: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.string)
};

/**
 * FormikArrayGroup default props
 * @type {Object}
 */
FormikArrayGroup.defaultProps = {
  category: 'Item',
  max: '6',
  values: []
};

export default FormikArrayGroup;
