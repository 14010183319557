import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ImageAvatar from './ImageAvatar';
import InitialAvatar from './InitialAvatar';
import styles from './Avatar.module.scss';

// Since <InitialAvatar> has lots of code regarding pulling out the initials and determining the background colors,
// extract those out to reduce noise in the <Avatar> component.
const Avatar = (props) => (
  <div
    className={classNames(styles.container, styles[props.size])}
    onClick={props.onClick}
    ref={props.innerRef}
    role="presentation"
  >
    {props.user.imageUrl ? <ImageAvatar {...props} /> : <InitialAvatar {...props} />}
  </div>
);

Avatar.propTypes = {
  /**
   * Required by Menu (Profile)
   */
  innerRef: PropTypes.func,

  /**
   * Required by Menu (Profile). The only thing onClick does is to open the context menu.
   */
  onClick: PropTypes.func,

  /**
   * The predefined size for Avatars. Possible value: 'small', 'medium', 'large', 'jumbo'.
   * small starts at 36px * 36px; each size is 50% larger than the previous one. Default size is 'small'.
   */
  size: PropTypes.string,

  /**
   * The user object from Auth0, but we only need several fields.
   */
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    lastName: PropTypes.string.isRequired
  }).isRequired
};

Avatar.defaultProps = {
  innerRef: null,
  onClick: null,
  size: 'small'
};

export default Avatar;
