import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Field, FieldArray } from 'formik';
import styles from 'components/form/formik/FormikGroup.module.scss';
import { Actions } from 'components/layout';
import { Button } from 'components/button';
import { FormikSection as Section } from 'components/form/formik';
import { selectors } from 'state';
import { testimonialDefaults } from './groupDefaults';

/**
 * Contains a repeating collection of field group for testimonials.
 * @function TestimonialsGroup
 * @param {Object} props
 * @return {Object}
 */
const TestimonialsGroup = ({ category, max, media, name, values }) => (
  <FieldArray name={name}>
    {(arrayHelpers) => (
      <Fragment>
        <Actions>
          <Button
            disabled={values && values.length >= max}
            type="button"
            icon="add"
            intent="primary"
            onClick={() => arrayHelpers.push(testimonialDefaults)}
          >
            {`Add ${category}`}
          </Button>
        </Actions>
        {values &&
          values.length > 0 &&
          values.map((value, index) => (
            /* eslint-disable react/no-array-index-key */
            <div className={styles.repeater} key={index}>
              <Section.Pane>
                <Field name={`${name}.${index}.testimonialName`} value={`${value}.testimonialName`}>
                  {() => (
                    <Section.Input
                      id={`${name}.${index}.testimonialName`}
                      label="Name"
                      name={`${name}.${index}.testimonialName`}
                    />
                  )}
                </Field>
                <Field name={`${name}.${index}.testimonialCompany`} value={`${value}.testimonialCompany`}>
                  {() => (
                    <Section.Input
                      id={`${name}.${index}.testimonialCompany`}
                      label="Company (Optional)"
                      name={`${name}.${index}.testimonialCompany`}
                    />
                  )}
                </Field>
                <Field name={`${name}.${index}.testimonialBody`} value={`${value}.testimonialBody`}>
                  {() => (
                    <Section.TextArea
                      id={`${name}.${index}.testimonialBody`}
                      label="Quote"
                      name={`${name}.${index}.testimonialBody`}
                    />
                  )}
                </Field>
              </Section.Pane>
              <Section.Pane>
                <Field name={`${name}.${index}.testimonialImageId`} value={`${value}.testimonialImageId`}>
                  {() => (
                    <Section.Dropdown
                      display="filename"
                      defaultOption="/defaults/avatar.svg"
                      id={`${name}.${index}.testimonialImageId`}
                      label="Testimonial Image"
                      name={`${name}.${index}.testimonialImageId`}
                      options={media}
                      preview
                      value="id"
                    />
                  )}
                </Field>
              </Section.Pane>
              <Actions>
                <Button icon="trash" intent="danger" onClick={() => arrayHelpers.remove(index)} type="button">
                  {`Remove ${category}`}
                </Button>
              </Actions>
            </div>
          ))}
      </Fragment>
    )}
  </FieldArray>
);

/**
 * Map state to props
 * @param {Object} state
 * @return {Object}
 */
const mapState = (state) => ({
  media: selectors.media.getAllImages(state)
});

/**
 * TestimonialsGroup prop types
 * @type {Object}
 */
TestimonialsGroup.propTypes = {
  category: PropTypes.string,
  name: PropTypes.string.isRequired,
  max: PropTypes.string,
  media: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      filename: PropTypes.string,
      url: PropTypes.string
    })
  ),
  values: PropTypes.arrayOf(
    PropTypes.shape({
      testimonialBody: PropTypes.string.isRequired,
      testimonialCompany: PropTypes.string,
      testimonialImageId: PropTypes.number,
      testimonialName: PropTypes.string.isRequired
    })
  )
};

/**
 * TestimonialsGroup default props
 * @type {Object}
 */
TestimonialsGroup.defaultProps = {
  category: 'Item',
  max: '6',
  media: [],
  values: []
};

export default connect(mapState)(TestimonialsGroup);
