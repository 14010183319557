import { configureStore } from 'redux-starter-kit';
import middleware from './middleware';
import { reducer } from './modules';

const store = configureStore({
  middleware,
  preloadedState: undefined,
  reducer
});

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./modules', () => store.replaceReducer(reducer));
}

export default store;
