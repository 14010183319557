import React from 'react';
import ProgressRing from './ProgressRing';
import Spinner from './Spinner';

const Loader = () => (
  <div>Please use either Loader.ProgressRing or Loader.Spinner, instead of using Loader directly.</div>
);

Loader.ProgressRing = ProgressRing;
Loader.Spinner = Spinner;

export default Loader;
