import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formik';
import { Actions } from 'components/layout';
import { Button } from 'components/button';
import { NavigationPrompt } from 'components/navigation';

/**
 * Wraps the form component from the Formik Library and includes action buttons.
 * @class FormikForm
 * @extends {Component}
 */
class FormikForm extends Component {
  /**
   * FormikForm prop types
   * @type {Object}
   */
  static propTypes = {
    buttonText: PropTypes.string,
    children: PropTypes.node.isRequired,
    dirty: PropTypes.bool.isRequired,
    isValid: PropTypes.bool.isRequired
  };

  /**
   * FormikForm default props
   * @type {Object}
   */
  static defaultProps = {
    buttonText: 'Save Changes'
  };

  /**
   * Constructor
   */
  constructor(props) {
    super(props);
    this.state = {
      showActions: false
    };
    this.onScroll = this.onScroll.bind(this);
  }

  /**
   * ComponentDidMount
   * @return {Void}
   */
  componentDidMount() {
    const content = document.getElementById('content');
    content.scrollTop = 0;
    content.addEventListener('scroll', this.onScroll);
  }

  /**
   * ComponentWillUnmount
   * @return {Void}
   */
  componentWillUnmount() {
    document.getElementById('content').removeEventListener('scroll', this.onScroll);
  }

  /**
   * Event handler for showing fixed actions on scroll.
   * @return {Void}
   */
  onScroll() {
    this.setState({ showActions: document.getElementById('content').scrollTop > 50 });
  }

  /**
   * Renders the FormikForm component.
   * @return {Object}
   */
  render() {
    const { buttonText, children, dirty, isValid } = this.props;
    return (
      <Form>
        <Actions>
          <Button disabled={!dirty || !isValid} intent="success" type="submit">
            {buttonText}
          </Button>
        </Actions>
        {children}
        <NavigationPrompt dirty={dirty} />
        <Actions fixed show={this.state.showActions && dirty}>
          <Button disabled={!isValid} intent="success" type="submit">
            {buttonText}
          </Button>
        </Actions>
      </Form>
    );
  }
}

export default FormikForm;
