/* eslint react/button-has-type: off */
/**
 * Disabled that rule because it doesn't recognize dynamic props.
 * https://github.com/yannickcr/eslint-plugin-react/issues/1555
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import requiredIf from 'components/prop-types';
import { Icon } from 'components/icon';
import styles from './Button.module.scss';

const Button = ({ children, disabled, icon, innerRef, intent, onClick, shape, type }) => {
  let iconIntent;

  // 'default' & 'danger' has white background and colorful text/icon. All other intents have colorful background and white text/icon.
  switch (intent) {
    case 'danger': // Red
    case 'default': // Dark blue & grayish
      iconIntent = intent;
      break;
    default:
      iconIntent = 'withinButton'; // White
  }

  if (disabled) iconIntent = 'disabled';

  return (
    <button
      className={classNames(styles.container, styles[`${intent}-${shape}`], styles[shape], {
        [styles.iconOnlyContainer]: !children
      })}
      disabled={disabled}
      onClick={onClick}
      onKeyPress={onClick}
      ref={innerRef}
      tabIndex="0"
      type={type}
    >
      {icon && (
        <span className={classNames({ [styles.iconWithText]: children })}>
          <Icon intent={iconIntent} name={icon} />
        </span>
      )}
      {children && <span className={styles.text}>{children}</span>}
    </button>
  );
};

Button.propTypes = {
  children: requiredIf(PropTypes.string, { icon: null }),

  disabled: PropTypes.bool,

  icon: requiredIf(PropTypes.string, { children: null }),

  /**
   * Required by Evergreen's Popover to use this as trigger.
   */
  innerRef: PropTypes.func,

  /**
   * Determines the color of the button
   */
  intent: PropTypes.oneOf(['danger', 'default', 'primary', 'success']),

  onClick: requiredIf(PropTypes.func, { type: 'button' }),

  /**
   * Determines the button shape.
   */
  shape: PropTypes.oneOf(['square', 'link']),

  /**
   * For HTML submit
   */
  type: PropTypes.oneOf(['button', 'submit'])
};

Button.defaultProps = {
  children: null,
  disabled: false,
  icon: null,
  innerRef: null,
  intent: 'default',
  onClick: null,
  shape: 'square',
  type: 'button'
};

export default React.memo(Button);
