import React from 'react';
import { FormikSection as Section } from 'components/form/formik';
import { Link } from 'components/navigation';
import { colorOptions, themeOptions } from './appearanceOptions';

/**
 * A form for updating a site's appearance.
 * @function AppearanceSection
 * @return {Object}
 */
const AppearanceSection = () => (
  <Section>
    <h3>Appearance</h3>
    <Section.Pane>
      <Section.Select
        id="appearanceColorScheme"
        label="Colour Scheme"
        name="content.appearanceColorScheme"
        options={colorOptions}
      />
      <Section.Select id="theme" label="Site Theme" name="theme" options={themeOptions} />
    </Section.Pane>
    <Section.Pane>
      <Section.Input
        id="linksApplyOnline"
        label={
          <div>
            <span>
              Apply Now (External Link) &mdash; <em>Don&apos;t have one? Try </em>
            </span>
            <Link
              external
              to="https://help.engageapps.ca/en/articles/3416373-adding-finmo-online-application-to-your-site"
            >
              <em>Finmo</em>
            </Link>
          </div>
        }
        name="content.linksApplyOnline"
        placeholder="e.g., https://johndoe.com/application"
      />
      <Section.Input id="title" label="Site Title" name="content.title" />
    </Section.Pane>
  </Section>
);

export default AppearanceSection;
