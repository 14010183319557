/**
 * Default values for site content.
 * @type {Object}
 */
export const defaultValues = {
  agentAddress: '',
  agentAnalytics: '',
  agentBiography: '',
  agentFacebook: '',
  agentHeading: '',
  agentInstagram: '',
  agentIntroduction: '',
  agentLicences: [],
  agentLinkedIn: '',
  agentPhone: '',
  agentTwitter: '',
  calendarScript: '',
  features: [],
  linksApplyOnline: '',
  ratesDescription: '',
  resources: [],
  resourceDescription: '',
  testimonials: [],
  testimonialCompany: ''
};

/**
 * Maps API field names to user-friendly names.
 * @type {Object}
 */
export const transformFieldNames = {
  'content.agentAddress': 'Office Address',
  'content.agentAnalytics': 'Google Analytics Tracking ID',
  'content.agentBiography': 'Biography',
  'content.agentEmail': 'Email Address',
  'content.agentFacebook': 'Facebook URL',
  'content.agentFirstName': 'First Name',
  'content.agentHeading': 'About Heading',
  'content.agentInstagram': 'Instagram URL',
  'content.agentIntroduction': 'Introduction',
  'content.agentLastName': 'Last Name',
  'content.agentLicences': 'Licence Number(s)',
  'content.agentLinkedIn': 'LinkedIn URL',
  'content.agentPhone': 'Phone Number',
  'content.agentTitle': 'Position Title',
  'content.agentTwitter': 'Twitter URL',
  'content.appearanceColorScheme': 'Colour Scheme',
  'content.calendarEnabled': 'Calendar Enabled',
  'content.calendarScript': 'Calendar Script',
  'content.displayEngageBadge': 'Display Engage Badge',
  'content.features.featureDescription': 'Feature Description',
  'content.features.featureHeading': 'Feature Heading',
  'content.features.featureIcon': 'Feature Icon',
  'content.featuresEnabled': 'Features Enabled',
  'content.heroDescription': 'Hero Description',
  'content.heroHeading': 'Hero Heading',
  'content.heroImageId': 'Hero Image',
  'content.linksApplyOnline': 'Apply Online',
  'content.ratesDescription': 'Rates Description',
  'content.ratesEnabled': 'Rates Enabled',
  'content.ratesHeading': 'Rates Heading',
  'content.ratesShowLogos': 'Show Lender Logos',
  'content.resources': 'Resources',
  'content.resources.resourceAssetId': 'Resource Asset',
  'content.resources.resourceDescription': 'Resource Description',
  'content.resources.resourceName': 'Resource Name',
  'content.resourcesEnabled': 'Resources Enabled',
  'content.testimonials.testimonialBody': 'Quote',
  'content.testimonials.testimonialCompany': 'Company',
  'content.testimonials.testimonialImageId': 'Testimonial Image',
  'content.testimonials.testimonialName': 'Testimonial Name',
  'content.testimonialsEnabled': 'Testimonials Enabled',
  'content.title': 'Site Title',
  name: 'Site Name'
};
