import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'components/layout';
import Submit from './Submit';

const SubmitModal = ({ onClose, show, ...props }) => (
  <Modal onClose={onClose} show={show}>
    <Submit closeModal={onClose} {...props} />
  </Modal>
);

/**
 * SubmitModal prop types
 * @type {Object}
 */
SubmitModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
};

export default SubmitModal;
