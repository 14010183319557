/**
 * Imported Segment's Menu and Popover. Check their Menu API doc at
 * https://evergreen.segment.com/components/menu/
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Menu as EvergreenMenu, Popover, Position } from 'evergreen-ui';
import { IconButton } from 'components/button';
import Divider from './Divider';
import Group from './Group';
import Item from './Item';
import RouterItem from './RouterItem';

/**
 * By default Popover doesn't support clicking anywhere inside the popover to close. Hence the `close` function and the <div> wrapping:
 * https://evergreen.segment.com/components/popover/
 */
const Menu = ({ children, trigger, ...props }) => (
  <Popover
    content={({ close }) => (
      <div onClick={close} role="presentation">
        <EvergreenMenu {...props}>{children}</EvergreenMenu>
      </div>
    )}
    position={Position.BOTTOM_LEFT}
  >
    {trigger}
  </Popover>
);

Menu.propTypes = {
  /**
   * The options. Use <Menu.Group> and <Menu.Item>.
   */
  children: PropTypes.node.isRequired,

  /**
   * This is the element you click to open the menu, such as IconButton and Profile.
   * Trigger must be a React component that contains `onClick` and `innerRef` (both are functions)
   * because Popover needs that to manage focus; otherwise it won't open. Check <Button> and <Avatar> for examples.
   */
  trigger: PropTypes.node
};

Menu.defaultProps = {
  /**
   * If not specified, we just display 3 dots on a horizontal line.
   */
  trigger: <IconButton name="more" />
};

Menu.Divider = Divider;
Menu.Group = Group;
Menu.Item = Item;
Menu.RouterItem = RouterItem;

export default Menu;
