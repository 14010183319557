import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Field } from 'formik';
import HelperText from 'components/form/HelperText';
import Label from 'components/form/Label';
import FormikTextArea from './FormikTextArea';
import styles from './FormikGroup.module.scss';

/**
 * Container component for a label, textarea field and error message. For use in Formik forms.
 * @function FormikTextAreaGroup
 * @param {Object} props
 * @return {Object}
 */
const FormikTextAreaGroup = ({ id, label, name, placeholder, rows }) => (
  <div className={styles.container}>
    <Label htmlFor={id}>{label}</Label>
    <Field id={id} name={name} placeholder={placeholder} rows={rows} component={FormikTextArea} />
    <div className={styles.errorMessage}>
      <ErrorMessage name={name} intent="danger" size="medium" component={HelperText} />
    </div>
  </div>
);

/**
 * FormikTextArea prop types
 * @type {Object}
 */
FormikTextAreaGroup.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  rows: PropTypes.string
};

/**
 * FormikTextArea default props
 * @type {Object}
 */
FormikTextAreaGroup.defaultProps = {
  placeholder: null,
  rows: '3'
};

export default FormikTextAreaGroup;
