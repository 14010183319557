import moment from 'moment';
import { transformFieldNames } from './constants';
import { deepTransform, setDefault, setNull } from './private';
import { transformationSchema } from './schemas';

/**
 * Converts a UTC timestamp into user-friendly local time.
 * @param {String} datetime
 * @param {String} [format='MMM D YYYY, h:mm a']
 * @return {String}
 */
export const formatDate = (datetime, format = 'MMM D YYYY, h:mm a') => moment(datetime).format(format);

/**
 * Prepares payload data for server by recursively replacing empty strings with null values.
 * @param {Object} data
 * @return {Object}
 */
export const setDefaultValuesToNull = (data) => deepTransform(data, setNull);

/**
 * Recursively replaces null values with default values.
 * @param {Object} data
 * @return {Object}
 */
export const setNullValuesToDefault = (data) => deepTransform(data, setDefault);

/**
 * Transforms form data before updating form state or submitting to the server.
 * @param {Object} data
 * @return {Object}
 */
export function transformData(data) {
  const clone = { ...data };
  if (clone.name) {
    clone.name = clone.name.trim().toLowerCase();
  }
  Object.keys(clone.content).forEach((key) => {
    if (typeof clone.content[key] === 'string') {
      clone.content[key] = clone.content[key].trim();
    }
    if (transformationSchema[key]) {
      clone.content[key] = transformationSchema[key](clone.content[key]);
    }
  });
  return clone;
}

/**
 * Transforms nested errors to an array of messages.
 * @param {Object[]} errors
 * @return {Array}
 */
export const transformErrors = (errors, schema = transformFieldNames) => {
  const newErrors = [];
  errors.forEach((error) => {
    const newError = schema[error.parameter]
      ? `${schema[error.parameter]}: ${error.message}`
      : `${error.parameter}: ${error.message}`;
    newErrors.push(newError);
  });
  return newErrors;
};
