import * as Yup from 'yup';

/**
 * Yup validation schema for Testimonials form
 * @type {Object}
 */
const TestimonialsSchema = Yup.object().shape({
  content: Yup.object().shape({
    testimonials: Yup.array().of(
      Yup.object().shape({
        testimonialBody: Yup.string().required('Testimonial quote is required.'),
        testimonialCompany: Yup.string(),
        testimonialImageId: Yup.number()
          .positive('Testimonial image ID must be a positive integer.')
          .nullable(),
        testimonialName: Yup.string().required('Testimonial name is required.')
      })
    ),
    testimonialsEnabled: Yup.boolean().required('Testimonial enabled is required.')
  })
});

export default TestimonialsSchema;
