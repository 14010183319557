import axios from 'axios';
import { combineReducers } from 'redux';
import { normalize } from 'normalizr';
import { siteTypes as types } from 'state/actions';
import schema from 'state/schema';
import { setDefaultValuesToNull, transformErrors } from 'transformations';

/**
 * Sites byIdReducer
 * @param {Object} [state={}]
 * @param {Object} action
 * @return {Object}
 */
const byIdReducer = (state = {}, action) => {
  switch (action.type) {
    case types.CREATE_SUCCESS:
      return {
        ...action.payload.entities.sites,
        ...state
      };
    case types.DESTROY_SUCCESS: {
      const { [action.payload.siteId]: omit, ...newState } = state;
      return newState;
    }
    case types.FETCH_BY_ID_SUCCESS:
    case types.UPDATE_SUCCESS:
      return {
        ...state,
        [action.payload.id]: action.payload
      };
    case types.FETCH_SUCCESS:
      return {
        ...action.payload.entities.sites
      };
    default:
      return state;
  }
};

/**
 * Sites errorReducer
 * @param {Object} [state=null]
 * @param {Object} action
 * @return {Object}
 */
const errorReducer = (state = null, action) => {
  switch (action.type) {
    case types.CREATE:
    case types.DESTROY:
    case types.FETCH:
    case types.FETCH_BY_ID:
    case types.UPDATE:
      return null;
    case types.CREATE_FAILED:
    case types.DESTROY_FAILED:
    case types.FETCH_BY_ID_FAILED:
    case types.FETCH_FAILED:
    case types.UPDATE_FAILED:
      return action.error.response;
    default:
      return state;
  }
};

/**
 * Determines whether a loader/spinner should be displayed during a site action.
 * @param {Boolean} [state=false]
 * @param {Object} action
 * @return {Boolean} - true if awaiting response from server, false otherwise.
 */
const isLoadingReducer = (state = false, action) => {
  switch (action.type) {
    case types.CREATE:
      return true;
    case types.CREATE_FAILED:
    case types.CREATE_FAILED_422:
    case types.CREATE_SUCCESS:
      return false;
    default:
      return state;
  }
};

/**
 * Combines all reducers into SitesReducer export.
 * @type {Object}
 */
export default combineReducers({
  byId: byIdReducer,
  error: errorReducer,
  isLoading: isLoadingReducer
});

/**
 * Action creator for when create site fails.
 * @param {*} error
 * @return {Object}
 */
const createFailed = (error) =>
  error.response.data.code === 422
    ? {
        type: types.CREATE_FAILED_422,
        error: transformErrors(error.response.data.errors)
      }
    : {
        type: types.CREATE_FAILED,
        error
      };

/**
 * Action creator for creating a new site.
 * @param {Object} response
 * @return {Object}
 */
const createSuccess = (response) => {
  const payload = normalize(response, schema.site);
  payload.userMessage = `Site Created: ${response.content.title}`;
  return {
    type: types.CREATE_SUCCESS,
    payload
  };
};

/**
 * Action creator for when destroying a site fails.
 * @param {Object} error
 * @return {Object}
 */
const destroyFailed = (error) => ({
  type: types.DESTROY_FAILED,
  error
});

/**
 * Action creator for when destroying a site succeeds.
 * @param {Object} response
 * @param {Number} siteId
 * @return {Object}
 */
const destroySuccess = (response, siteId) => ({
  type: types.DESTROY_SUCCESS,
  payload: { ...response, siteId }
});

/**
 * Action creator for when fetch sites fails.
 * @param {Object} error
 * @return {Object}
 */
const fetchAllFailed = (error) => ({
  type: types.FETCH_FAILED,
  error
});

/**
 * Acion creator for fetching all sites.
 * @param {Object} response
 * @return {Object}
 */
const fetchAllSuccess = (response) => ({
  type: types.FETCH_SUCCESS,
  payload: normalize(response, [schema.site])
});

/**
 * Action creator for when fetching site by ID fails.
 * @param {Object} error
 * @return {Object}
 */
const fetchByIdFailed = (error) => ({
  type: types.FETCH_BY_ID_FAILED,
  error
});

/**
 * Action creator for fetching site by ID.
 * @param {Object} response
 * @return {Object}
 */
const fetchByIdSuccess = (response) => ({
  type: types.FETCH_BY_ID_SUCCESS,
  payload: response
});

/**
 * Action creator for when update site fails.
 * @param {Object} error
 */
const updateFailed = (error) =>
  error.response.data.code === 422
    ? {
        type: types.UPDATE_FAILED_422,
        error: transformErrors(error.response.data.errors)
      }
    : {
        type: types.UPDATE_FAILED,
        error
      };

/**
 * Acion creator for updating a site by ID.
 * @param {Object} response
 * @return {Object}
 */
const updateSuccess = (response) => ({
  type: types.UPDATE_SUCCESS,
  payload: {
    ...response,
    userMessage: `${response.content.title} Updated! Changes can take up to 10 minutes to go live.`
  }
});

/**
 * Create new site thunk.
 * @param {Object} data
 * @return {Promise}
 */
export const create = (payload) => (dispatch) => {
  dispatch({ type: types.CREATE });
  return axios
    .post('/sites', setDefaultValuesToNull(payload))
    .then(({ data }) => dispatch(createSuccess(data)))
    .catch((error) => dispatch(createFailed(error)));
};

/**
 * Destroy site thunk.
 * @param {Object} siteId
 * @return {Promise}
 */
export const destroy = (siteId) => (dispatch) => {
  dispatch({ type: types.DESTROY });
  return axios
    .delete(`/sites/${siteId}`)
    .then(({ data }) => dispatch(destroySuccess(data, siteId)))
    .catch((error) => dispatch(destroyFailed(error)));
};

/**
 * Fetch all sites thunk.
 * @return {Promise}
 */
export const fetchAll = () => (dispatch) => {
  dispatch({ type: types.FETCH });
  return axios
    .get('/sites')
    .then(({ data }) => dispatch(fetchAllSuccess(data)))
    .catch((error) => dispatch(fetchAllFailed(error)));
};

/**
 * Fetch site by ID thunk.
 * @param {Number} siteId
 * @return {Promise}
 */
export const fetchById = (siteId) => (dispatch) => {
  dispatch({ type: types.FETCH_BY_ID });
  return axios
    .get(`/sites/${siteId}`)
    .then(({ data }) => dispatch(fetchByIdSuccess(data)))
    .catch((error) => dispatch(fetchByIdFailed(error)));
};

/**
 * Update a site thunk.
 * @param {Object} payload
 * @param {Number} siteId
 * @return {Promise}
 */
export const update = (siteId, payload) => (dispatch) => {
  dispatch({ type: types.UPDATE });
  return axios
    .patch(`/sites/${siteId}`, setDefaultValuesToNull(payload))
    .then(({ data }) => dispatch(updateSuccess(data)))
    .catch((error) => dispatch(updateFailed(error)));
};
