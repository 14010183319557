import * as Yup from 'yup';

/**
 * Yup validation schema for About form.
 * @type {Object}
 */
const AboutSchema = Yup.object().shape({
  content: Yup.object().shape({
    agentAddress: Yup.string(),
    agentBiography: Yup.string(),
    agentEmail: Yup.string()
      .email('Must be a valid email address.')
      .required('Email is required.'),
    agentFacebook: Yup.string().url('Facebook URL must be a valid URL.'),
    agentFirstName: Yup.string().required('First Name is required.'),
    agentHeading: Yup.string(),
    agentInstagram: Yup.string().url('Instagram URL must be a valid URL.'),
    agentIntroduction: Yup.string(),
    displayEngageBadge: Yup.boolean().required('Display Engage Badge is required.'),
    agentLastName: Yup.string().required('Last Name is required.'),
    agentLicences: Yup.array().of(Yup.string().required('Broker licence cannot be empty.')),
    agentLinkedIn: Yup.string().url('LinkedIn URL must be a valid URL.'),
    agentPhone: Yup.string(),
    agentTitle: Yup.string().required('Agent title is required.'),
    agentTwitter: Yup.string().url('Twitter URL must be a valid URL.')
  })
});

export default AboutSchema;
