import PropTypes from 'prop-types';

const requiredIf = (propType, conditions) => (props, propName, componentName) => {
  const conditionsList = Object.entries(conditions);
  const isRequired = conditionsList.every(([key, value]) => props[key] === value);

  // Don't throw false positives at 0, false or ''; those shouldn't be treated as "prop missing"
  const propValue = props[propName];
  if (isRequired && (propValue === undefined || propValue === null || Number.isNaN(propValue))) {
    return new Error(
      `The prop ${propName} is required if "${conditionsList.map(([key, value]) => `${key}=${value}`).join(' ')}"`
    );
  }

  if (propValue) {
    PropTypes.checkPropTypes({ [propName]: propType }, { [propName]: propValue }, 'prop', componentName);
  }

  return null;
};

export default requiredIf;
