import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { FormikForm as Form } from 'components/form/formik';
import { ResourcesSchema } from './schemas';
import { ResourcesSection } from './sections';

/**
 * A container for forms under the 'Resources' tab.
 * @function ResourcesForm
 * @param {Object} props
 * @return {Object}
 */
const ResourcesForm = ({ content, onSubmit }) => (
  <Formik
    enableReinitialize
    initialValues={{
      content: {
        resources: content.resources,
        resourcesEnabled: content.resourcesEnabled
      }
    }}
    onSubmit={onSubmit}
    validationSchema={ResourcesSchema}
  >
    {({ dirty, isValid, values }) => (
      <Form dirty={dirty} isValid={isValid}>
        <ResourcesSection values={values} />
      </Form>
    )}
  </Formik>
);

/**
 * ResourcesForm prop types
 * @type {Object}
 */
ResourcesForm.propTypes = {
  content: PropTypes.shape({
    resources: PropTypes.arrayOf(
      PropTypes.shape({
        resourceAssetId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        resourceDescription: PropTypes.string,
        resourceName: PropTypes.string
      })
    ),
    resourcesEnabled: PropTypes.bool.isRequired
  }).isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ResourcesForm;
