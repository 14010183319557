/**
 * An indeterminate spinner. If we need to show the progress, use ProgressRing instead.
 * https://www.w3schools.com/howto/howto_css_loader.asp
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Spinner.module.scss';

const Spinner = ({ local, show }) =>
  show && (
    <div className={classNames(styles.overlay, { [styles.localOverlay]: local })}>
      <div className={classNames(styles.loader, { [styles.localLoader]: local })} />
    </div>
  );

Spinner.propTypes = {
  /**
   * If local, the spinner and its gray overlay is on top of the local parent instead of the full viewport.
   */
  local: PropTypes.bool,

  /**
   * The parent component can do {this.state.isLoading && <Loader.Spinner />}, but this is cleaner:
   * <Loader.Spinner show={this.state.isLoading} />. Either way works.
   */
  show: PropTypes.bool
};

Spinner.defaultProps = {
  local: false,
  show: true
};

export default Spinner;
