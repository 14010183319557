import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Field } from 'formik';
import { withRouter } from 'react-router-dom';
import HelperText from 'components/form/HelperText';
import Label from 'components/form/Label';
import { Link } from 'components/navigation';
import FormikDropdown from './FormikDropdown';
import styles from './FormikGroup.module.scss';

/**
 * Container component for a label, select field and error message. For use in Formik forms.
 * @function FormikDropdownGroup
 * @param {Object} props
 * @return {Object}
 */
const FormikDropdownGroup = ({ display, defaultOption, id, label, match, name, options, preview, rounded, value }) => (
  <div className={styles.container}>
    <Label htmlFor={id}>{label}</Label>
    <Field
      component={FormikDropdown}
      display={display}
      defaultOption={defaultOption}
      id={id}
      name={name}
      options={options}
      preview={preview}
      rounded={rounded}
      title={label}
      value={value}
    />
    {options.length === 0 && (
      <Link internal to={`/sites/${match.params.id}/update/media`}>
        Upload your {label} here first.
      </Link>
    )}
    <div className={styles.errorMessage}>
      <ErrorMessage name={name} intent="danger" size="medium" component={HelperText} />
    </div>
  </div>
);

/**
 * FormikSelect prop types
 * @type {Object}
 */
FormikDropdownGroup.propTypes = {
  display: PropTypes.string,
  defaultOption: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  preview: PropTypes.bool,
  rounded: PropTypes.bool,
  value: PropTypes.string
};

/**
 * FormikSelect default props
 * @type {Object}
 */
FormikDropdownGroup.defaultProps = {
  display: 'display',
  defaultOption: null,
  preview: false,
  rounded: false,
  value: 'value'
};

export default withRouter(FormikDropdownGroup);
