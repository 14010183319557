import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FormikSection as Section } from 'components/form/formik';
import { selectors } from 'state';

/**
 * A form for updating hero information.
 * @function HeroSection
 * @param {Object} props
 * @return {Object}
 */
const HeroSection = ({ media }) => (
  <Section>
    <h3>Hero</h3>
    <Section.Pane>
      <Section.Input id="heroHeading" label="Heading" name="content.heroHeading" />
      <Section.TextArea id="heroDescription" label="Description" name="content.heroDescription" />
    </Section.Pane>
    <Section.Pane>
      <Section.Dropdown
        display="filename"
        defaultOption="/defaults/house.svg"
        id="heroImage"
        label="Hero Image"
        name="content.heroImageId"
        options={media}
        preview
        value="id"
      />
    </Section.Pane>
  </Section>
);

/**
 * HeroSection prop types
 * @type {Object}
 */
HeroSection.propTypes = {
  media: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      filename: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired
};

/**
 * Map state to props
 * @param {Object} state
 * @return {Object}
 */
const mapState = (state) => ({
  media: selectors.media.getAllImages(state)
});

export default connect(mapState)(HeroSection);
