import React from 'react';
import PropTypes from 'prop-types';
import ReactRouterNavigationPrompt from 'react-router-navigation-prompt';
import { Button } from 'components/button';
import { Modal } from 'components/layout';
import styles from './NavigationPrompt.module.scss';
/**
 * Prompts users to save, confirm or cancel if they attempt to change routes with unsaved changed.
 * @function NavigationPrompt
 * @param {Object} props
 * @return {Object}
 */
const NavigationPrompt = ({ dirty }) => (
  <ReactRouterNavigationPrompt when={dirty}>
    {({ onConfirm, onCancel }) => (
      <Modal show onClose={onCancel}>
        <div className={styles.container}>
          <p>You have unsaved changes!</p>
          <p>Are you sure that you want to leave this page?</p>
          <div>
            <Button onClick={onCancel}>Stay Here</Button>
            <Button intent="primary" onClick={onConfirm}>
              Leave Without Saving
            </Button>
          </div>
        </div>
      </Modal>
    )}
  </ReactRouterNavigationPrompt>
);

NavigationPrompt.propTypes = {
  dirty: PropTypes.bool.isRequired
};

export default NavigationPrompt;
