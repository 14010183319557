import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Table as EvergreenTable } from 'evergreen-ui';
import { Card } from 'components/layout';
import Body from './Body';
import Head from './Head';
import Row from './Row';
import styles from './Table.module.scss';
import TextCell from './TextCell';
import TextHeaderCell from './TextHeaderCell';

const Table = ({ children, className, ...props }) => (
  <Card className={classNames(styles.container, className)} {...props}>
    <EvergreenTable>{children}</EvergreenTable>
  </Card>
);

Table.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

Table.defaultProps = {
  className: ''
};

// Keep our API the same as Evergreen's.
Table.Body = Body;
Table.Head = Head;
Table.Row = Row;
Table.TextCell = TextCell;
Table.TextHeaderCell = TextHeaderCell;

export default Table;
