import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { FormikForm as Form } from 'components/form/formik';
import { AboutSchema } from './schemas';
import { AboutSection, ContactInformationSection, SocialMediaSection } from './sections';

/**
 * A container for forms under the 'About' tab.
 * @function AboutForm
 * @param {Object} props
 * @return {Object}
 */
const AboutForm = ({ content, onSubmit }) => (
  <Formik
    initialValues={{
      content: {
        agentAddress: content.agentAddress,
        agentBiography: content.agentBiography,
        agentEmail: content.agentEmail,
        agentFacebook: content.agentFacebook,
        agentFirstName: content.agentFirstName,
        agentHeading: content.agentHeading,
        agentInstagram: content.agentInstagram,
        agentIntroduction: content.agentIntroduction,
        agentLastName: content.agentLastName,
        agentLicences: content.agentLicences,
        agentLinkedIn: content.agentLinkedIn,
        agentPhone: content.agentPhone,
        agentTitle: content.agentTitle,
        agentTwitter: content.agentTwitter,
        displayEngageBadge: content.displayEngageBadge
      }
    }}
    onSubmit={onSubmit}
    validationSchema={AboutSchema}
  >
    {({ dirty, isValid, values }) => (
      <Form dirty={dirty} isValid={isValid}>
        <ContactInformationSection values={values} />
        <SocialMediaSection />
        <AboutSection />
      </Form>
    )}
  </Formik>
);

/**
 * AboutForm prop types
 * @type {Object}
 */
AboutForm.propTypes = {
  content: PropTypes.shape({
    agentAddress: PropTypes.string,
    agentBiography: PropTypes.string,
    agentEmail: PropTypes.string,
    agentFacebook: PropTypes.string,
    agentFirstName: PropTypes.string,
    agentHeading: PropTypes.string,
    agentInstagram: PropTypes.string,
    agentIntroduction: PropTypes.string,
    agentLastName: PropTypes.string,
    agentLicences: PropTypes.arrayOf(PropTypes.string),
    agentLinkedIn: PropTypes.string,
    agentPhone: PropTypes.string,
    agentTitle: PropTypes.string,
    agentTwitter: PropTypes.string,
    displayEngageBadge: PropTypes.bool
  }).isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default AboutForm;
