import React from 'react';
import PropTypes from 'prop-types';
import { ErrorMessage, Field } from 'formik';
import HelperText from 'components/form/HelperText';
import Label from 'components/form/Label';
import FormikSelect from './FormikSelect';
import styles from './FormikGroup.module.scss';

/**
 * Container component for a label, select field and error message. For use in Formik forms.
 * @function FormikSelectGroup
 * @param {Object} props
 * @return {Object}
 */
const FormikSelectGroup = ({ display, id, label, name, options, value }) => (
  <div className={styles.container}>
    <Label htmlFor={id}>{label}</Label>
    <Field display={display} id={id} name={name} options={options} value={value} component={FormikSelect} />
    <div className={styles.errorMessage}>
      <ErrorMessage name={name} intent="danger" size="medium" component={HelperText} />
    </div>
  </div>
);

/**
 * FormikSelect prop types
 * @type {Object}
 */
FormikSelectGroup.propTypes = {
  display: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  value: PropTypes.string
};

/**
 * FormikSelect default props
 * @type {Object}
 */
FormikSelectGroup.defaultProps = {
  display: 'display',
  value: 'value'
};

export default FormikSelectGroup;
