import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'components/icon';
import styles from './FormikSelect.module.scss';

/**
 * A select field for use with the Formik library.
 * @function FormikSelect
 * @param {Object} props
 * @return {Object}
 */
const FormikSelect = ({ display, field, options, value }) => (
  <div className={styles.container}>
    <Icon name="caret-down" />
    <select {...field}>
      {options.map((option) => (
        <option key={option[value]} value={option[value]}>
          {option[display]}
        </option>
      ))}
    </select>
  </div>
);

/**
 * FormikSelect prop types
 * @type {Object}
 */
FormikSelect.propTypes = {
  display: PropTypes.string.isRequired,
  field: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      display: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  ).isRequired,
  value: PropTypes.string.isRequired
};

export default FormikSelect;
