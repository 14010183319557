import React from 'react';
import PropTypes from 'prop-types';
import requiredIf from 'components/prop-types';
import Button from './Button';
import styles from './RoundedButton.module.scss';

const RoundedButton = ({ children, icon, onClick, type }) => (
  <span className={styles.container}>
    <Button icon={icon} onClick={onClick} type={type}>
      {children}
    </Button>
  </span>
);

RoundedButton.propTypes = {
  /**
   * A child for this button. Could be plain text or a React node.
   */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,

  /**
   * The icon string from font awesome. Based on the design, we only support the free, "solid" icons here:
   * https://fontawesome.com/icons?d=gallery&s=solid&m=free
   */
  icon: PropTypes.string.isRequired,

  /**
   * The function called if the button is clicked.
   */
  onClick: requiredIf(PropTypes.func, { type: 'button' }),

  /**
   * If set to 'submit', it's a submit button for a form so onClick becomes optional.
   */
  type: PropTypes.oneOf(['button', 'submit'])
};

RoundedButton.defaultProps = {
  onClick: null,
  type: 'button'
};

export default React.memo(RoundedButton);
