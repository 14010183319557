import { createSelector } from 'reselect';
import { denormalize } from 'normalizr';
import schema from 'state/schema';
import { setNullValuesToDefault } from 'transformations';

/**
 * Retrieves all sites by ID.
 * @param {Object} state
 * @return {Object}
 */
export const getAllSites = (state) => state.sites.byId;

/**
 * Retrieves all sites sorted by title.
 * @param {Function[]} - An array of memoized input selectors.
 * @param {Function} - The transform function.
 * @return {Array} - A sorted array of sites.
 */
export const getAll = createSelector([getAllSites], (sites) =>
  Object.keys(sites)
    .map((id) => denormalize(sites[id], schema.site, { sites }))
    .sort((site1, site2) => (site1.content.title < site2.content.title ? -1 : 1))
);

/**
 * Retrieves a site by ID.
 * @param {Object} state
 * @param {Number} siteId
 * @return {Object} site
 */
export const getById = (state, siteId) => state.sites.byId[siteId];

/**
 * Retrieves a site by ID and transforms content for initial values.
 * @param {Function[]} - An array of memoized input selectors.
 * @param {Function} - The transform function.
 * @return {Object} - A transformed site.
 */
export const getSite = createSelector([getById], (site) => (site ? setNullValuesToDefault(site) : null));

/**
 * Returns the number of sites belonging to the current user.
 * @param {Object} state
 * @return {Object}
 */
export const getSiteCount = (state) => Object.keys(state.sites.byId).length;

/**
 * Returns any errors related to the sites.
 * @param {Object} state
 * @return {Object}
 */
export const getErrors = (state) => state.sites.error;

/**
 * Returns true if the authenticated user has at least one site.
 * @param {Object} state
 * @return {Boolean}
 */
export const hasAny = (state) => Object.keys(state.sites.byId).length > 0;

/**
 * Shows a spinner or progress ring while awaiting a response from the API server.
 * @param {Object} state
 * @return {Boolean} Whether the client is busy.
 */
export const isLoading = (state) => state.sites.isLoading;
