import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, FieldArray } from 'formik';
import { FormikSection as Section } from 'components/form/formik';
import styles from 'components/form/formik/FormikGroup.module.scss';
import { Actions } from 'components/layout';
import { Button } from 'components/button';
import featureIcons from './featureIcons';
import { featureDefaults } from './groupDefaults';

/**
 * Contains a repeating collection of field group for testimonials.
 * @function FeaturesGroup
 * @param {Object} props
 * @return {Object}
 */
const FeaturesGroup = ({ category, max, name, values }) => (
  <FieldArray name={name}>
    {(arrayHelpers) => (
      <Fragment>
        <Actions>
          <Button
            disabled={values && values.length >= max}
            type="button"
            icon="add"
            intent="primary"
            onClick={() => arrayHelpers.push(featureDefaults)}
          >
            {`Add ${category}`}
          </Button>
        </Actions>
        {values &&
          values.length > 0 &&
          values.map((value, index) => (
            /* eslint-disable react/no-array-index-key */
            <div className={styles.repeater} key={index}>
              <Section.Pane>
                <Field name={`${name}.${index}.featureHeading`} value={`${value}.featureHeading`}>
                  {() => (
                    <Section.Input
                      id={`${name}.${index}.featureHeading`}
                      label="Heading"
                      name={`${name}.${index}.featureHeading`}
                    />
                  )}
                </Field>
                <Field name={`${name}.${index}.featureDescription`} value={`${value}.featureDescription`}>
                  {() => (
                    <Section.TextArea
                      id={`${name}.${index}.featureDescription`}
                      label="Description"
                      name={`${name}.${index}.featureDescription`}
                    />
                  )}
                </Field>
              </Section.Pane>
              <Section.Pane>
                <Field name={`${name}.${index}.featureIcon`} value={`${value}.featureIcon`}>
                  {() => (
                    <Section.Dropdown
                      className={styles.icons}
                      defaultOption="/icons/check-solid.svg"
                      id={`${name}.${index}.featureIcon`}
                      label="Feature Icon"
                      name={`${name}.${index}.featureIcon`}
                      options={featureIcons}
                      preview
                    />
                  )}
                </Field>
              </Section.Pane>
              <Actions>
                <Button icon="trash" intent="danger" onClick={() => arrayHelpers.remove(index)} type="button">
                  {`Remove ${category}`}
                </Button>
              </Actions>
            </div>
          ))}
      </Fragment>
    )}
  </FieldArray>
);

/**
 * FeaturesGroup prop types
 * @type {Object}
 */
FeaturesGroup.propTypes = {
  category: PropTypes.string,
  name: PropTypes.string.isRequired,
  max: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      featureDescription: PropTypes.string.isRequired,
      featureHeading: PropTypes.string.isRequired,
      featureIcon: PropTypes.string
    })
  ).isRequired
};

/**
 * FeaturesGroup default props
 * @type {Object}
 */
FeaturesGroup.defaultProps = {
  category: 'Item',
  max: '6'
};

export default FeaturesGroup;
