import React from 'react';
import PropTypes from 'prop-types';
import styles from './Modal.module.scss';

const Modal = ({ children, onClose, show }) =>
  show && (
    <div className={styles.modalContainer}>
      <div className={styles.modalContent}>
        <button type="button" className={styles.close} onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool
};

Modal.defaultProps = {
  show: false
};

export default Modal;
