import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { FormikForm as Form } from 'components/form/formik';
import { FeaturesSchema } from './schemas';
import { FeaturesSection } from './sections';

/**
 * A container for forms under the 'Testimonials' tab.
 * @function FeaturesForm
 * @param {Object} props
 * @return {Object}
 */
const FeaturesForm = ({ content, onSubmit }) => (
  <Formik
    initialValues={{
      content: {
        features: content.features,
        featuresEnabled: content.featuresEnabled
      }
    }}
    onSubmit={onSubmit}
    validationSchema={FeaturesSchema}
  >
    {({ dirty, isValid, values }) => (
      <Form dirty={dirty} isValid={isValid}>
        <FeaturesSection content={content} values={values} />
      </Form>
    )}
  </Formik>
);

/**
 * FeaturesForm prop types
 * @type {Object}
 */
FeaturesForm.propTypes = {
  content: PropTypes.shape({
    features: PropTypes.arrayOf(
      PropTypes.shape({
        featureDescription: PropTypes.string.isRequired,
        featureHeading: PropTypes.string.isRequired,
        featureIcon: PropTypes.string
      })
    ),
    featuresEnabled: PropTypes.bool.isRequired
  }).isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default FeaturesForm;
