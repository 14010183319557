import React from 'react';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import styles from './Link.module.scss';

const NavLink = ({ children, exact, to }) => (
  <RouterLink activeClassName={styles.onThisRoute} className={styles.container} exact={exact} to={to}>
    {children}
  </RouterLink>
);

/**
 * NavLink prop types
 * @type {Object}
 */
NavLink.propTypes = {
  children: PropTypes.node.isRequired,
  exact: PropTypes.bool,
  to: PropTypes.string.isRequired
};

/**
 * NavLink default props
 * @type {Object}
 */
NavLink.defaultProps = {
  exact: false
};

export default NavLink;
