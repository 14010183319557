import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';

/**
 * Show Sentry Error Dialog.
 * @type {Function}
 */
const showErrorDialog = () => Sentry.showReportDialog();

class ErrorBoundary extends Component {
  /**
   * Constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  /**
   * Handle Uncaught Error
   * @param {String} error
   * @param {Object} errorInfo
   * @return {Void}
   */
  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  /**
   * Render Component
   * @return {Object}
   */
  render() {
    if (this.state.error) {
      return (
        <button onClick={showErrorDialog} onKeyPress={showErrorDialog} type="button">
          Report feedback
        </button>
      );
    }
    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.element.isRequired
};

export default ErrorBoundary;
