/**
 * Retrieves the breadcrumbs from state.
 * @param {Object} state
 * @return {Object}
 */
export const get = (state) => state.breadcrumbs;

export default {
  get
};
