import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Option from 'components/form/dropdown/Option';
import Preview from 'components/form/dropdown/Preview';
import styles from 'components/form/dropdown/Dropdown.module.scss';
import { Button } from 'components/button';
import { Icon } from 'components/icon';
import { Search } from 'components/search';

/**
 * A custom dropdown field with
 * @class FormikDropdown
 * @extends PureComponent
 */
class FormikDropdown extends PureComponent {
  /**
   * FormikDropdown prop types
   * @type {Object}
   */
  static propTypes = {
    display: PropTypes.string.isRequired,
    defaultOption: PropTypes.string,
    field: PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    }).isRequired,
    form: PropTypes.shape({
      setFieldValue: PropTypes.func
    }).isRequired,
    multiSelect: PropTypes.bool,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string
      })
    ).isRequired,
    placeholder: PropTypes.string,
    preview: PropTypes.bool,
    rounded: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired
  };

  /**
   * FormikDropdown default props
   * @type {Object}
   */
  static defaultProps = {
    defaultOption: null,
    multiSelect: false,
    placeholder: 'Search...',
    preview: false
  };

  /**
   * Constructor
   * @param {Object} props
   * @return {Void}
   */
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: -1,
      list: [],
      mouseOver: 0,
      searchTerm: '',
      show: false,
      showPreview: false
    };
    this.onOptionMouseEnter = this.onOptionMouseEnter.bind(this);
    this.onOptionMouseLeave = this.onOptionMouseLeave.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.close = this.close.bind(this);
    this.renderImage = this.renderImage.bind(this);
    this.search = this.search.bind(this);
    this.toggle = this.toggle.bind(this);
    this.unset = this.unset.bind(this);
  }

  /**
   * ComponentDidMount
   * @return {Void}
   */
  componentDidMount() {
    this.setState({
      selectedIndex: this.props.options.findIndex((option) => option[this.props.value] === this.props.field.value),
      list: this.props.options.map((option) => ({
        selected: option[this.props.value] === this.props.field.value,
        ...option
      }))
    });
  }

  /**
   * Triggers when the cursor enters an option.
   * @param {Number} index
   * @return {Void}
   */
  onOptionMouseEnter(index) {
    this.setState({
      mouseOver: index,
      showPreview: true
    });
  }

  /**
   * Triggers when the cursor leaves an option.
   * @return {Void}
   */
  onOptionMouseLeave() {
    this.setState({
      showPreview: false
    });
  }

  /**
   * Handles onSelect action.
   * @param {Number} index
   * @return {Void}
   */
  onSelect(index) {
    this.setState((prevState) => {
      // This makes sure this.state.selectedIndex contains the right index. It can be null if user deselected.
      let newlySelected = index;
      if (prevState.selectedIndex === index) {
        newlySelected = null;
      }

      // This makes sure this.state.list contains the correct `selected` value, so onChange returns the right array.
      const newList = [...prevState.list];
      newList[index].selected = !newList[index].selected;
      // Updates the field value in Formik state.
      this.props.form.setFieldValue(this.props.field.name, newList[index][this.props.value]);

      // Explicitly check against -1 due to return value of findIndex when no index found.
      if (!this.props.multiSelect && prevState.selectedIndex !== -1) {
        newList[prevState.selectedIndex].selected = false;
      }

      return {
        selectedIndex: newlySelected,
        list: newList
      };
    }, this.report);
  }

  /**
   * Hides the dropdown component and clears the search term in local state.
   * @return {Void}
   */
  close() {
    this.setState({
      show: false,
      searchTerm: ''
    });
  }

  /**
   * Closes the dropdown component after a brief delay.
   * @return {Void}
   */
  report() {
    if (!this.props.multiSelect) {
      // Don't close right away; a tiny delay to let users see the little tick.
      setTimeout(this.close, 100);
    }
  }

  /**
   * Handles the option search/filter action.
   * @param {String} searchTerm
   * @return {Void}
   */
  search(searchTerm) {
    this.setState({
      searchTerm: searchTerm.trim()
    });
  }

  /**
   * Toggles whether the component should be displayed.
   * @return {Void}
   */
  toggle() {
    this.setState((prevState) => ({
      show: !prevState.show
    }));
  }

  /**
   * Clears the selected value
   * @return {Void}
   */
  unset() {
    this.setState((prevState) => {
      const newList = [...prevState.list];
      newList.forEach((option) => {
        /* eslint-disable no-param-reassign */
        option.selected = false;
      });
      // Clears the field value in Formik state.
      this.props.form.setFieldValue(this.props.field.name, null);
      return {
        selectedIndex: -1,
        list: newList
      };
    });
  }

  /**
   * Renders the selected or default image
   * @return {Object}
   */
  renderImage() {
    const { defaultOption, title } = this.props;
    const { selectedIndex, list } = this.state;
    const selectedOption = list[selectedIndex];
    if (selectedOption) {
      return <img src={selectedOption.url} alt={`${title} thumbnail`} />;
    }
    if (defaultOption) {
      return <img src={defaultOption} alt="default option" />;
    }
    return <div className={styles.imagePlaceholder} />;
  }

  /**
   * Renders the FormikDropdown component.
   */
  render() {
    const { display, placeholder, preview, rounded, title, value } = this.props;
    const { selectedIndex, list, mouseOver, searchTerm, show, showPreview } = this.state;
    const selectedOption = list[selectedIndex];
    const mouseOverOption = list[mouseOver];
    return (
      <div className={styles.container}>
        <div
          className={classNames(styles.imageContainer, {
            [styles.rounded]: rounded
          })}
        >
          {this.renderImage()}
        </div>
        <div className={styles.buttonContainer}>
          <Button
            disabled={list.length < 1}
            icon={selectedOption ? 'cross' : 'add'}
            intent={selectedOption ? 'danger' : 'default'}
            onClick={selectedOption ? this.unset : this.toggle}
          >
            {selectedOption ? `Remove Image` : `Set Image`}
          </Button>
        </div>
        {show && (
          <div
            className={classNames(styles.popup, {
              [styles.popupWithPreview]: preview
            })}
          >
            <div className={styles.titleHolder}>
              <div className={classNames(styles.row, styles.titleRow)}>
                <div className={styles.title}>{title}</div>
                <div
                  className={styles.closeIcon}
                  onClick={this.close}
                  onKeyPress={this.close}
                  role="button"
                  tabIndex="0"
                >
                  <Icon name="small-cross" />
                </div>
              </div>
              <Search onEscape={this.close} placeholder={placeholder} search={this.search} />
            </div>
            <div className={styles.scrolling} role="listbox">
              {list.map((option, index) =>
                option[display].toLowerCase().includes(searchTerm.toLowerCase()) ? (
                  <Option
                    index={index}
                    key={option[value]}
                    onFocus={this.onOptionMouseEnter}
                    onMouseEnter={this.onOptionMouseEnter}
                    onMouseLeave={this.onOptionMouseLeave}
                    onSelect={this.onSelect}
                    selected={option.selected}
                  >
                    {option[display]}
                  </Option>
                ) : null
              )}
            </div>
          </div>
        )}
        {preview && show && showPreview && (
          <div className={styles.previewContainer}>
            <Preview name={mouseOverOption[display]} url={mouseOverOption.url} />
          </div>
        )}
      </div>
    );
  }
}

export default FormikDropdown;
