import * as Yup from 'yup';

/**
 * Yup validation schema for Resources form
 * @type {Object}
 */
const ResourcesSchema = Yup.object().shape({
  content: Yup.object().shape({
    resources: Yup.array().of(
      Yup.object().shape({
        resourceAssetId: Yup.number()
          .positive('Resource asset ID must be a positive integer.')
          .nullable(),
        resourceDescription: Yup.string(),
        resourceName: Yup.string().required('Resource name is required.')
      })
    ),
    resourcesEnabled: Yup.boolean().required('Resources enabled is required.')
  })
});

export default ResourcesSchema;
