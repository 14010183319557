import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { actions, selectors } from 'state';
import { Subcontent } from 'components/layout';
import { Loader } from 'components/loader';
import { NewSiteForm } from 'forms';
import { transformData } from 'transformations';
import { newSiteCrumbs } from './breadcrumbs';

/**
 * NewSite page located at the /sites/new route.  A broker can add a new site from this page.
 * @class NewSite
 * @extends {Component}
 */
class NewSite extends Component {
  /**
   * NewSite proptypes
   * @type {Object}
   */
  static propTypes = {
    actions: PropTypes.shape({
      breadcrumbs: PropTypes.shape({
        update: PropTypes.func
      }),
      sites: PropTypes.shape({
        create: PropTypes.func,
        fetchAll: PropTypes.func
      }),
      snackbar: PropTypes.shape({
        clear: PropTypes.func
      })
    }).isRequired,
    canCreateSites: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired
  };

  /**
   * Constructor
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  /**
   * ComponentDidMount
   * @return {Void}
   */
  componentDidMount() {
    this.props.actions.sites.fetchAll().then(() => {
      if (!this.props.canCreateSites) this.setState({ redirect: true });
    });
    this.props.actions.breadcrumbs.update(newSiteCrumbs);
  }

  /**
   * Handles new site form submission.
   * @param {Object} values
   * @return {Void}
   */
  onSubmit(data, { resetForm }) {
    const transformedData = transformData(data);
    transformedData.content.title = `${data.content.agentFirstName} ${data.content.agentLastName}`;
    const payload = JSON.parse(JSON.stringify(transformedData));
    resetForm({ values: transformedData });
    this.props.actions.sites.create(payload).then((response) => {
      this.props.actions.snackbar.clear();
      if (!response.error) this.setState({ redirect: true });
    });
  }

  /**
   * Renders the NewSite component
   * @return {Object}
   */
  render() {
    return (
      <Subcontent>
        {this.state.redirect && <Redirect to="/sites" />}
        <Loader.Spinner show={this.props.isLoading} />
        <NewSiteForm onSubmit={this.onSubmit} />
      </Subcontent>
    );
  }
}

/**
 * Map state to props
 * @param {Object} state
 * @return {Object}
 */
const mapState = (state) => ({
  canCreateSites: selectors.permissions.canCreateSites(state),
  isLoading: selectors.sites.isLoading(state)
});

/**
 * Map dispatch to props
 * @param {Function} dispatch
 * @return {Object}
 */
const mapDispatch = (dispatch) => ({
  actions: {
    breadcrumbs: bindActionCreators(actions.breadcrumbs, dispatch),
    sites: bindActionCreators(actions.sites, dispatch),
    snackbar: bindActionCreators(actions.snackbar, dispatch)
  }
});

export default connect(mapState, mapDispatch)(NewSite);
