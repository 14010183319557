import React, { useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import partition from 'lodash/partition';
import ReactDropzone from 'react-dropzone';
import { Button, IconButton } from 'components/button';
import { Column, Row } from 'components/layout';
import { FiletypeIcon, Icon } from 'components/icon';
import { Status } from 'components/status';
import styles from './Submit.module.scss';

/**
 * @param {File[]} files - The list of File object
 * @return {String} A string of all the file names
 */
const getFileNames = (files) => files.map((file) => file.name).join(', ');

/**
 * @function Submit
 * @param {Object} props
 * @return {Object}
 */
const Submit = ({
  allowedMimeTypes,
  allowMultipleFiles,
  closeModal,
  contextId,
  contextModel,
  heading,
  horizontal,
  upload
}) => {
  const [accepted, setAccepted] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);

  /**
   * Determines whether staged files can be uploaded in their current state.
   * @return {Boolean}
   */
  const canUpload = () => {
    let context = true;
    if (contextModel) {
      accepted.forEach((file) => {
        if (!file.contextId) {
          context = false;
        }
      });
    }
    return accepted.length && context;
  };

  /**
   * Removes an accepted file.
   * @param {Object}
   */
  const removeFile = (index) => {
    setErrorMessage(null);
    const clone = [...accepted];
    clone.splice(index, 1);
    setAccepted(clone);
  };

  /**
   * updates the context for a single file.
   * @param {Object} file
   * @return {Void}
   */
  const updateContext = (file) => {
    const clone = [...accepted];
    const target = clone[clone.indexOf(file)];
    const { value } = document.getElementById(file.filename);
    target.contextId = value;
    setAccepted(clone);
  };

  /**
   * Renders the dynamic heading.
   * @return {Object}
   */
  const renderHeading = () => (heading || allowMultipleFiles ? 'Upload Files' : 'Upload a File');

  /**
   * Renders the preview for accepted files.
   * @param {Object} file
   * @return {Object}
   */
  const renderPreview = (file) => (
    <div key={`${file.filename}-${accepted.indexOf(file)}`} className={styles.previewHolder}>
      <div className={styles.previewImage}>
        {file.filedata.type.startsWith('image') ? (
          <img className={styles.preview} src={URL.createObjectURL(file.filedata)} alt="preview" />
        ) : (
          <FiletypeIcon mediaType={file.filedata.type} />
        )}
        <p className={styles.filename}>{file.filename}</p>
      </div>
      {contextModel && (
        <div className={styles.select}>
          <Icon name="caret-down" />
          <select id={file.filename} value={file.contextId} onChange={() => updateContext(file)}>
            <option id={file.filename} value="">
              Choose One...
            </option>
            {contextModel.map((context) => (
              <option key={context.id} value={context.id}>
                {context.name}
              </option>
            ))}
          </select>
        </div>
      )}
      <IconButton size={20} name="remove" intent="danger" onClick={() => removeFile(accepted.indexOf(file))} />
    </div>
  );

  /**
   * Uploads accepted files to the server.
   * @return {Void}
   */
  const sendToServer = () => {
    if (contextModel) {
      accepted.forEach((file) => {
        upload(file.contextId, file.filedata);
      });
    } else if (contextId) {
      accepted.forEach((file) => {
        upload(contextId, file.filedata);
      });
    } else {
      accepted.forEach((file) => {
        upload(file.filedata);
      });
    }
    setAccepted([]);
  };

  /**
   * @param {File[]} acceptedFiles
   * @param {File[]} rejectedFiles
   * @return {Void}
   */
  const updateFiles = (acceptedFiles, rejectedFiles) => {
    // Unlike what the doc says, if I drag 2 or more files, all of them will be rejected (instead of the 1st being accepted.
    if (!allowMultipleFiles && rejectedFiles.length >= 2) {
      setErrorMessage('Sorry, you can only upload 1 file at a time.');
      return;
    }

    // React Dropzone can handle file type validation only through the opened file window: it'll gray out non-valid files.
    // However, if the user drags, it'll accept everything regardless the "acceptedMimeTypes" props.
    const [correctFiles, wrongFiles] = partition(acceptedFiles, (file) => allowedMimeTypes.includes(file.type));
    if (wrongFiles.length) {
      setErrorMessage(
        `Sorry, this file cannot be uploaded because it has the wrong file type: ${getFileNames(wrongFiles)}`
      );
    } else {
      const maxFiles = 10;
      if (accepted.length + correctFiles.length > maxFiles) {
        setErrorMessage(`Sorry, but only ${maxFiles} files can be selected at a time.`);
      } else {
        const incomingFiles = correctFiles.map((filedata) => ({
          contextId: '',
          filedata,
          filename: filedata.name
        }));
        setErrorMessage(null);
        setAccepted(allowMultipleFiles ? [...accepted, ...incomingFiles] : incomingFiles);
      }
    }
  };

  return (
    <div className={styles.container}>
      <Row className={styles.firstRow}>
        <Column className={styles.left} flex={4}>
          <h5 className={styles.title}>{renderHeading()}</h5>
        </Column>
      </Row>
      <div className={classnames({ [styles.horizontal]: horizontal })}>
        <Row className={classnames({ [styles.oneThird]: horizontal })}>
          <Column>
            <ReactDropzone id="upload-zone" onDrop={updateFiles} multiple={allowMultipleFiles}>
              {({ getRootProps, getInputProps }) => {
                const customizedInputProps = {
                  ...getInputProps(),
                  accept: allowedMimeTypes
                };
                return (
                  <div {...getRootProps()} className={styles.dropzone}>
                    <Status
                      asset="undraw/add-files.svg"
                      caption={`Drag and drop ${allowMultipleFiles ? 'files' : 'a file'} here.`}
                    />
                    <input {...customizedInputProps} />
                    {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
                    <Button icon="selection" intent="primary" type="submit" form="upload-zone">
                      {allowMultipleFiles ? 'Select Files' : 'Select File'}
                    </Button>
                  </div>
                );
              }}
            </ReactDropzone>
          </Column>
        </Row>
        <Row className={classnames({ [styles.twoThirds]: horizontal })}>
          <Column>
            {accepted.length > 0 && (
              <div className={styles.stagedFiles}>{accepted.map((file) => renderPreview(file))}</div>
            )}
          </Column>
        </Row>
      </div>
      <Row className={classnames(styles.actions, styles.right)}>
        {closeModal && <Button onClick={closeModal}>Cancel</Button>}
        <Button disabled={!canUpload()} icon="cloud-upload" intent="success" onClick={sendToServer}>
          Upload
        </Button>
      </Row>
    </div>
  );
};

/**
 * Submit prop types
 * @type {Object}
 */
Submit.propTypes = {
  allowedMimeTypes: PropTypes.arrayOf(PropTypes.string),
  allowMultipleFiles: PropTypes.bool,
  closeModal: PropTypes.func,
  contextId: PropTypes.number,
  contextModel: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string
    })
  ),
  heading: PropTypes.string,
  horizontal: PropTypes.bool,
  upload: PropTypes.func.isRequired
};

/**
 * Submit default props
 * @type {Object}
 */
Submit.defaultProps = {
  allowedMimeTypes: [
    'application/msword',
    'application/pdf',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'image/bmp',
    'image/gif',
    'image/jpeg',
    'image/jpg',
    'image/png',
    'image/svg+xml',
    'image/tiff'
  ],
  allowMultipleFiles: false,
  closeModal: null,
  contextId: null,
  contextModel: null,
  heading: null,
  horizontal: false
};

export default React.memo(Submit);
