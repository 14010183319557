import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import styles from './Link.module.scss';

/**
 * Renders and internal or external link depending on props.
 * @function Link
 * @param {Object} props
 * @return {Object}
 */
const Link = ({ children, external, to }) =>
  external ? (
    <a className={styles.external} href={to} rel="noopener noreferrer" target="_blank">
      {children}
    </a>
  ) : (
    <RouterLink className={styles.internal} to={to}>
      {children}
    </RouterLink>
  );

/**
 * Link prop types
 * @type {Object}
 */
Link.propTypes = {
  children: PropTypes.node.isRequired,
  external: PropTypes.bool,
  to: PropTypes.string.isRequired
};

/**
 * Link default props
 * @type {Object}
 */
Link.defaultProps = {
  external: false
};

export default Link;
