import { getSiteCount } from './sites';

/**
 * Returns whether the current user is an admin.
 * @param {Object} state - The currentUser object
 * @return {Boolean}
 */
export const isAdmin = (state) => (state.users.currentUser ? state.users.currentUser.role === 'admin' : false);

/**
 * Returns whether the current user is an admin.
 * @param {Object} state - The currentUser object
 * @return {Boolean}
 */
export const isBroker = (state) => (state.users.currentUser ? state.users.currentUser.role === 'broker' : false);

/**
 * Returns whether the current user is a developer.
 * @param {Object} state - The currentUser object
 * @return {Boolean}
 */
export const isDeveloper = (state) => (state.users.currentUser ? state.users.currentUser.role === 'developer' : false);

/**
 * Returns whether the current user is an admin or developer.
 * @param {Object} state - The currentUser object
 * @return {Boolean}
 */
export const isAdminOrDeveloper = (state) => isAdmin(state) || isDeveloper(state);

/**
 * Returns whether the current user can create multiple sites.
 * @param {Object} state
 * @return {Object}
 */
export const canCreateSites = (state) => isAdminOrDeveloper(state) || getSiteCount(state) < 1;

/**
 * Returns whether the current user can delete sites.
 * @param {Object} state
 * @return {Object}
 */
export const canDeleteSites = (state) => isAdminOrDeveloper(state);
