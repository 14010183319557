import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icon } from 'components/icon';
import { selectors } from 'state';
import { APP_NAME, BASE_URL } from 'constants.js';
import styles from './Breadcrumb.module.scss';

/**
 * Breadcrumb component for navigation and tracking current location.
 * @function Breadcrumb
 * @param {Object} props
 */
const Breadcrumb = ({ breadcrumbs }) => {
  const clone = [...breadcrumbs];
  const currentView = clone.pop();
  return (
    <div className={styles.container}>
      <div className={styles.firstLine}>
        <Link className={styles.appName} to={BASE_URL}>
          {APP_NAME}
        </Link>
        {clone.map(({ name, url }) => (
          <span key={name}>
            <Icon className={styles.rightArrow} name="chevron-right" />
            {url.startsWith('http') ? (
              <a className={styles.page} href={url} target="_blank" rel="noopener noreferrer">
                {name}
              </a>
            ) : (
              <Link className={styles.page} to={url}>
                {name}
              </Link>
            )}
          </span>
        ))}
      </div>
      {currentView && (
        <div className={styles.secondLine}>
          {currentView.url.startsWith('http') ? (
            <a className={styles.page} href={currentView.url} target="_blank" rel="noopener noreferrer">
              {currentView.name}
            </a>
          ) : (
            <Link className={styles.page} to={currentView.url}>
              {currentView.name}
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

/**
 * Map state to props
 * @param {Object} state
 * @param {Object} props
 * @return {Object}
 */
const mapState = (state) => ({
  breadcrumbs: selectors.breadcrumbs.get(state)
});

/**
 * Breadcrumb prop types
 */
Breadcrumb.propTypes = {
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string
    })
  ).isRequired
};

export default connect(mapState)(Breadcrumb);
