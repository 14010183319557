import React from 'react';
import PropTypes from 'prop-types';
import styles from './Sidebar.module.scss';

const Sidebar = ({ children }) => (
  <aside className={styles.container}>
    <section className={styles.topSection}>
      <div className={styles.linkContainer}>{children}</div>
    </section>
  </aside>
);

Sidebar.propTypes = {
  children: PropTypes.node.isRequired
};

export default Sidebar;
