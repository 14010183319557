/**
 * Get the most recent error from state, which is a JavaScript Exception.
 * Don't use this to pull error message to inform the user. See getErrorMessage below.
 * @param {Object} state
 * @return {Exception|null}
 */
export const getError = (state) => state.users.error;

/**
 * Get the most recent error message from state. This is not the generic JavaScript message, but the hand
 * crafted one from the server. Use this selector if we want to show that message on the UI.
 * @param {Object|null} state
 * @return {String}
 */
export const getErrorMessage = (state) => {
  if (!getError(state)) return null;

  try {
    return state.users.error.response.data.message;
  } catch (e) {
    return "Oops! something went wrong, and we can't perform your operation. We apologize for the inconvenience; please try again later!";
  }
};

/**
 * @param {Object} state - The currentUser object
 * @return {Object}
 */
export const getCurrentUser = (state) => state.users.currentUser;

/**
 * Check if there is currently an error message.
 * @param {Object} state
 * @return {Boolean} Whether there is an error message.
 */
export const hasError = (state) => state.users.error !== null;

/**
 * Whenever a user logs out, the reducer nullifies the user's currentUser and error. Thus this is the best way to tell if a user is logged in
 * at any given moment. This means most components only need to be aware of Redux; they don't have to know about Auth0.
 * @param {Object} state
 * @return {boolean} Whether the user is logged in or not.
 */
export const isLoggedIn = (state) => state.users.currentUser !== null;
