import React from 'react';
import PropTypes from 'prop-types';
import styles from 'components/form/TextArea.module.scss';

/**
 * A textarea field for use with the Formik library.
 * @function FormikTextArea
 * @param {Object} props
 * @return {Object}
 */
const FormikTextArea = ({ field, form, rows, ...props }) => (
  <textarea {...field} {...props} className={styles.textarea} rows={rows} />
);

/**
 * FormikTextArea prop types
 * @type {Object}
 */
FormikTextArea.propTypes = {
  /* eslint-disable react/forbid-prop-types */
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  rows: PropTypes.string.isRequired
};

export default FormikTextArea;
