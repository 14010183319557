import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon } from 'components/icon';
import styles from './Option.module.scss';

class Option extends PureComponent {
  static propTypes = {
    children: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onSelect: PropTypes.func.isRequired,
    selected: PropTypes.bool
  };

  static defaultProps = {
    onMouseEnter: () => undefined,
    onMouseLeave: () => undefined,
    selected: false
  };

  constructor(props) {
    super(props);

    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  onMouseEnter() {
    this.props.onMouseEnter(this.props.index);
  }

  onMouseLeave() {
    this.props.onMouseLeave();
  }

  onSelect() {
    this.props.onSelect(this.props.index);
  }

  render() {
    return (
      <div
        aria-selected={this.props.selected}
        className={styles.container}
        onClick={this.onSelect}
        onFocus={this.onMouseEnter}
        onKeyPress={this.onSelect}
        onMouseEnter={this.onMouseEnter}
        onMouseLeave={this.onMouseLeave}
        role="option"
        tabIndex="0"
      >
        <span className={classNames({ [styles.hidden]: !this.props.selected })}>
          <Icon className={styles.icon} iconSize={18} name="small-tick" />
        </span>

        {this.props.children}
      </div>
    );
  }
}

export default Option;
