import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { IconButton } from 'components/button';
import { Breadcrumb } from 'components/breadcrumb';
import { Menu } from 'components/menu';
import { Profile } from 'components/profile';
import styles from './Topbar.module.scss';

// For now we need the login function since we don't have a login form/page yet.
// Once we do, this Topbar will only be displayed to logged in users, so only show them logout icon.
const Topbar = ({ currentUser, isLoggedIn, login, logout, logoUrl }) => (
  <div className={styles.container}>
    <RouterLink to="/sites" className={styles.logoContainer}>
      <img alt="logo" className={styles.logo} src={logoUrl} />
    </RouterLink>
    <div className={styles.rightSection}>
      <div className={styles.breadcrumbContainer}>
        <Breadcrumb />
      </div>
      <div className={styles.rightIcons}>
        {isLoggedIn ? (
          <Profile currentUser={currentUser}>
            <Menu.Group>
              <Menu.Item style={{ opacity: '0.4' }} icon="user">
                Profile...
              </Menu.Item>
              <Menu.Item style={{ opacity: '0.4' }} icon="cog">
                Settings...
              </Menu.Item>
            </Menu.Group>
            <Menu.Divider />
            <Menu.Item icon="log-out" intent="danger" onSelect={logout}>
              Logout
            </Menu.Item>
          </Profile>
        ) : (
          <IconButton name="user" onClick={login} />
        )}
      </div>
    </div>
    <div className={styles.clearfix} />
  </div>
);

Topbar.propTypes = {
  currentUser: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    imageUrl: PropTypes.string,
    lastName: PropTypes.string.isRequired
  }),
  isLoggedIn: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  logoUrl: PropTypes.string.isRequired
};

Topbar.defaultProps = {
  currentUser: null
};

export default Topbar;
