import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import styles from 'components/navigation/Topbar.module.scss';
import { AUTH0_ENTITLEMENT, LOGO_URL } from 'constants.js';
import { Button } from 'components/button';
import { Content, Main, Subcontent } from 'components/layout';
import { Menu } from 'components/menu';
import { Profile } from 'components/profile';
import { selectors } from 'state';
import { Status } from 'components/status';
import { withAuth } from 'components/auth';

/**
 * View for unauthorized users.
 * @function Register
 * @param {Object} props
 * @return {Object}
 */
const Register = ({ auth, currentUser, isLoggedIn }) =>
  isLoggedIn && currentUser.entitlements.includes(AUTH0_ENTITLEMENT) ? (
    <Redirect to="/sites" />
  ) : (
    <Fragment>
      <div className={styles.container}>
        <img alt="logo" className={styles.logo} src={LOGO_URL} />
        <div className={styles.rightSection}>
          <div className={styles.breadcrumbContainer} />
          <div className={styles.rightIcons}>
            {isLoggedIn && (
              <Profile currentUser={currentUser}>
                <Menu.Group>
                  <Menu.Item style={{ opacity: '0.4' }} icon="user">
                    Profile...
                  </Menu.Item>
                  <Menu.Item style={{ opacity: '0.4' }} icon="cog">
                    Settings...
                  </Menu.Item>
                </Menu.Group>
                <Menu.Divider />
                <Menu.Item icon="log-out" intent="danger" onSelect={auth.logout}>
                  Logout
                </Menu.Item>
              </Profile>
            )}
          </div>
        </div>
        <div className={styles.clearfix} />
      </div>
      <Main>
        <Content id="content">
          <Subcontent>
            <Status
              asset="undraw/good-team.svg"
              caption="Contact us via live chat or at support@engageapps.ca to request access."
              heading="You need permission to use this application."
            />
          </Subcontent>
          <Button icon="user" intent="primary" onClick={auth.logout}>
            Logout
          </Button>
        </Content>
      </Main>
    </Fragment>
  );

/**
 * Register prop types
 * @type {Object}
 */
Register.propTypes = {
  auth: PropTypes.shape({
    login: PropTypes.func,
    logout: PropTypes.func,
    renewSession: PropTypes.func
  }).isRequired,
  currentUser: PropTypes.shape({
    entitlements: PropTypes.arrayOf(PropTypes.string),
    firstName: PropTypes.string,
    imageUrl: PropTypes.string,
    lastName: PropTypes.string
  }),
  isLoggedIn: PropTypes.bool.isRequired
};

/**
 * Register default props
 * @type {Object}
 */
Register.defaultProps = {
  currentUser: null
};

/**
 * Map state to props.
 * @param {Object} state
 * @return {Object}
 */
const mapState = (state) => ({
  currentUser: selectors.users.getCurrentUser(state),
  isLoggedIn: selectors.users.isLoggedIn(state)
});

export default connect(mapState)(withAuth(Register));
