import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { FormikForm as Form } from 'components/form/formik';
import { AnalyticsSection, CalendarSection, RatesSection } from './sections';
import { SettingsSchema } from './schemas';

/**
 * A container for forms under the 'Settings' tab.
 * @function SettingsForm
 * @param {Object} props
 * @return {Object}
 */
const SettingsForm = ({ content, onSubmit }) => (
  <Formik
    initialValues={{
      content: {
        agentAnalytics: content.agentAnalytics,
        calendarEnabled: content.calendarEnabled,
        calendarScript: content.calendarScript,
        ratesDescription: content.ratesDescription,
        ratesEnabled: content.ratesEnabled,
        ratesHeading: content.ratesHeading,
        ratesShowLogos: content.ratesShowLogos
      }
    }}
    onSubmit={onSubmit}
    validationSchema={SettingsSchema}
  >
    {({ dirty, isValid, values }) => (
      <Form dirty={dirty} isValid={isValid}>
        <RatesSection values={values} />
        <CalendarSection values={values} />
        <AnalyticsSection />
      </Form>
    )}
  </Formik>
);

/**
 * SettingsForm prop types
 * @type {Object}
 */
SettingsForm.propTypes = {
  content: PropTypes.shape({
    agentAnalytics: PropTypes.string,
    calendarEnabled: PropTypes.bool,
    calendarScript: PropTypes.string,
    ratesDescription: PropTypes.string,
    ratesEnabled: PropTypes.bool,
    ratesHeading: PropTypes.string,
    ratesShowLogos: PropTypes.bool
  }).isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default SettingsForm;
