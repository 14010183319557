import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { BASE_URL, LOGO_URL } from 'constants.js';
import { NavLink as Link, Sidebar, SubNavigation, Topbar } from 'components/navigation';
import { selectors } from 'state';
import { withAuth } from 'components/auth';
import Content from './Content';
import Main from './Main';

/**
 * Container component for rendering the primary view, which includes the topbar and sidebar.
 * @function Primary
 * @extends {Component}
 */
const Primary = ({ auth, children, currentUser, isLoggedIn }) => (
  <Fragment>
    <Topbar
      currentUser={currentUser}
      isLoggedIn={isLoggedIn}
      login={auth.login}
      logout={auth.logout}
      logoUrl={LOGO_URL}
    />
    <Main>
      <Sidebar>
        <SubNavigation vertical>
          <Link to={BASE_URL}>Sites</Link>
        </SubNavigation>
      </Sidebar>
      <Content id="content">{children}</Content>
    </Main>
  </Fragment>
);

/**
 * Primary prop types
 * @type {Object}
 */
Primary.propTypes = {
  auth: PropTypes.shape({
    login: PropTypes.func,
    logout: PropTypes.func,
    renewSession: PropTypes.func
  }).isRequired,
  children: PropTypes.node.isRequired,
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    imageUrl: PropTypes.string,
    lastName: PropTypes.string
  }),
  isLoggedIn: PropTypes.bool.isRequired
};

/**
 * Primary default props
 * @type {Object}
 */
Primary.defaultProps = {
  currentUser: null
};

const mapState = (state) => ({
  currentUser: selectors.users.getCurrentUser(state),
  isLoggedIn: selectors.users.isLoggedIn(state)
});

export default connect(mapState)(withAuth(Primary));
