/**
 * Schema of transformations on all applicable content fields.
 * @type {Object}
 */
export const transformationSchema = {
  agentEmail: (agentEmail) => agentEmail.toLowerCase(),
  agentFacebook: (agentFacebook) => agentFacebook.toLowerCase(),
  agentInstagram: (agentInstagram) => agentInstagram.toLowerCase(),
  agentLicences: (agentLicences) => agentLicences.map((licence) => licence.trim()).filter((licence) => !!licence),
  agentLinkedIn: (agentLinkedIn) => agentLinkedIn.toLowerCase(),
  agentTwitter: (agentTwitter) => agentTwitter.toLowerCase(),
  features: (features) =>
    features.map((feature) => {
      Object.keys(feature).forEach((key) => {
        if (typeof feature[key] === 'string') {
          /* eslint-disable no-param-reassign */
          feature[key] = feature[key].trim();
        }
      });
      return feature;
    }),
  resources: (resources) =>
    resources.map((resource) => {
      Object.keys(resource).forEach((key) => {
        if (typeof resource[key] === 'string') {
          /* eslint-disable no-param-reassign */
          resource[key] = resource[key].trim();
        }
        if (key === 'resourceAssetId') {
          resource[key] = parseInt(resource[key], 10);
        }
        if (key === 'resourceUrl') {
          delete resource[key];
        }
      });
      return resource;
    }),
  linksApplyOnline: (linksApplyOnline) => linksApplyOnline.toLowerCase(),
  testimonials: (testimonials) =>
    testimonials.map((testimonial) => {
      Object.keys(testimonial).forEach((key) => {
        if (typeof testimonial[key] === 'string') {
          /* eslint-disable no-param-reassign */
          testimonial[key] = testimonial[key].trim();
        }
      });
      return testimonial;
    })
};

export default transformationSchema;
