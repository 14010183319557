import React from 'react';
import PropTypes from 'prop-types';
import styles from './Avatar.module.scss';

/**
 * Display the user's profile image in a square box with 50% border-radius.
 */
const ImageAvatar = ({ user }) => (
  <div className={styles.background}>
    <img alt="profile" className={styles.image} src={user.imageUrl} />
  </div>
);

ImageAvatar.propTypes = {
  user: PropTypes.shape({
    imageUrl: PropTypes.string.isRequired
  }).isRequired
};

export default ImageAvatar;
