import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NotFound } from 'components/errors';
import { Primary } from 'components/layout';
import { Route, Switch } from 'react-router-dom';
import { selectors } from 'state';
import { Snackbar } from 'components/snackbar';
import NewSite from './NewSite';
import SitesList from './SitesList';
import UpdateSite from './UpdateSite';

/**
 * A top-level page component for rendering Site views
 * @function Sites
 * @param {Object} props
 * @return {Object}
 */
const Sites = ({ snackbar }) => (
  <Primary>
    <Switch>
      <Route exact path="/sites" component={SitesList} />
      <Route exact path="/sites/new" component={NewSite} />
      <Route path="/sites/:id/update" component={UpdateSite} />
      <Route path="/sites" render={() => <NotFound resource="Site" link="/sites" page="Sites" />} />
    </Switch>
    {snackbar.messages &&
      snackbar.messages.map((message, index) => (
        /* eslint-disable react/no-array-index-key */
        <Snackbar key={`${message}.${index}`} intent={snackbar.intent}>
          {message}
        </Snackbar>
      ))}
  </Primary>
);

/**
 * Sites prop types
 * @type {Object}
 */
Sites.propTypes = {
  snackbar: PropTypes.shape({
    intent: PropTypes.string,
    messages: PropTypes.arrayOf(PropTypes.string)
  }).isRequired
};

/**
 * Map state to props
 * @param {Object} state
 * @param {Object}
 */
const mapState = (state) => ({
  snackbar: selectors.snackbar.get(state)
});

export default connect(mapState)(Sites);
