import React from 'react';
import PropTypes from 'prop-types';
import styles from './Label.module.scss';

const Label = ({ children, htmlFor }) => (
  <label className={styles.label} htmlFor={htmlFor}>
    {children}
  </label>
);

Label.propTypes = {
  children: PropTypes.node.isRequired,
  htmlFor: PropTypes.string
};

Label.defaultProps = {
  htmlFor: null
};

export default Label;
