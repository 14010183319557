import React from 'react';
import { FormikSection as Section } from 'components/form/formik';

/**
 * A form for updating information about the agent.
 * @function AboutSection
 * @return {Object}
 */
const AboutSection = () => (
  <Section>
    <h3>About</h3>
    <Section.Pane>
      <Section.Input id="agentHeading" label="Heading" name="content.agentHeading" placeholder="Meet John Doe" />
      <Section.TextArea
        id="agentIntroduction"
        label="Introduction"
        name="content.agentIntroduction"
        placeholder="Hi, my name is John Doe and..."
      />
    </Section.Pane>
    <Section.Pane>
      <Section.TextArea
        id="agentBiography"
        label="Biography"
        name="content.agentBiography"
        placeholder="John Doe has more than 15 years of experience in the mortgage industry and..."
        rows="5"
      />
    </Section.Pane>
  </Section>
);

export default AboutSection;
