import React from 'react';
import PropTypes from 'prop-types';
import styles from './Avatar.module.scss';

/**
 * An algorithm to determine the background color of an Avatar if the user profile image is not provided, and we use initials.
 * @param {String} initial - A 2 digit string.
 * @return {String} a hex color code.
 */
const getBackgroundColor = (initial) => {
  const colorCodes = ['#FBE6A2', '#D2EEF3', '#FAE2E2', '#FAE3CD', '#E4E7EB'];
  const charCodeSum = initial.charCodeAt(0) + initial.charCodeAt(1);

  return colorCodes[charCodeSum % colorCodes.length];
};

const InitialAvatar = ({ user }) => {
  const initial = `${user.firstName[0]}${user.lastName[0]}`;
  const backgroundStyle = { backgroundColor: getBackgroundColor(initial) };

  return (
    <div className={styles.background} style={backgroundStyle}>
      <div className={styles.initial}>{initial}</div>
    </div>
  );
};

InitialAvatar.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired
  }).isRequired
};

export default InitialAvatar;
