import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './Actions.module.scss';

const Actions = ({ children, fixed, show }) => (
  <div className={classNames(styles.container, { [styles.fixed]: fixed, [styles.show]: show })}>{children}</div>
);

/**
 * Actions prop types
 * @type {Object}
 */
Actions.propTypes = {
  children: PropTypes.node.isRequired,
  fixed: PropTypes.bool,
  show: PropTypes.bool
};

/**
 * Actions default props
 * @type {Object}
 */
Actions.defaultProps = {
  fixed: false,
  show: false
};

export default Actions;
