import { combineReducers } from 'redux';
import breadcrumbsReducer, * as breadcrumbs from './breadcrumbs';
import mediaReducers, * as media from './media';
import sitesReducer, * as sites from './sites';
import snackbarReducer, * as snackbar from './snackbar';
import usersReducer, * as users from './users';

export const reducer = combineReducers({
  breadcrumbs: breadcrumbsReducer,
  media: mediaReducers,
  sites: sitesReducer,
  snackbar: snackbarReducer,
  users: usersReducer
});

export default {
  breadcrumbs,
  media,
  sites,
  snackbar,
  users
};
