import React from 'react';
import PropTypes from 'prop-types';
import styles from './Content.module.scss';

const Content = ({ children, id }) => (
  <div className={styles.container} id={id}>
    {children}
  </div>
);

/**
 * Content prop types
 * @type {Object}
 */
Content.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string
};

/**
 * Content default props
 * @type {Object}
 */
Content.defaultProps = {
  id: null
};

export default Content;
